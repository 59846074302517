// Third-party libraries
import React, { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Stack,
  Typography,
  Box,
  Chip,
  Collapse,
  Grid,
  CircularProgress,
  Backdrop,
} from "@mui/material";

// Local modules
import { ProductLandingContext } from "../../../utils/stateHandlers/contexts";
import { api } from "../../../utils";
import states from "../../../utils/stateHandlers/initialStates";
import { landingReducer } from "../../../utils/stateHandlers/reducers";
import ProductTiles from "./ProductTiles";
import ProductFilter from "./ProductFilter";
import LoadingIndicator from "./LoadingIndicator";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";

export default function ProductLandingPage(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const [landingState, landingDispatch] = useReducer(
    landingReducer,
    states.initialProductLandingState
  );
  const location = useLocation();
  const { state } = location || {};
  const {
    pageLoaded,
    itemsLoaded,
    showFilters,
    filters,
    bestSellers,
    products,
    searchQuery,
  } = landingState;
  const category = props.Category;
  const isMill = props.isMill;

  function getFilterValues(filter, filterGroup) {
    // Iterate through each key in the filter object dynamically
    Object.keys(filter).forEach((filterKey) => {
      // Find the corresponding group in filterGroup
      const group = filterGroup.find((group) => group.name === filterKey);
      if (group) {
        // For each filter item in filterKey (like StoneRowCount)
        filter[filterKey].forEach((filterItem) => {
          const matchingValue = group.values.find(
            (value) => value.label === filterItem.label
          );
          if (matchingValue) {
            // Assign the value to the filter item
            filterItem.value = matchingValue.value;
          }
        });
      }
    });
    return filter;
  }

  function updateFilterValues(newFilters) {
    (newFilters || []).forEach((newFilter) => {
      const oldFilter = filters.find(
        (filter) => filter.name === newFilter.name
      );
      if (oldFilter) {
        oldFilter.values = newFilter.values;
      }
    });
  }

  function filterBestSellers() {
    const bsFilter = searchQuery.fields.find(
      (searchField) => searchField.name === "BestSeller"
    );

    const bsFilterField = filters.find(
      (filter) => filter.name === "BestSeller"
    );

    if (bsFilter) {
      if (bsFilter.values.length > 0) {
        bsFilter.values = [];
        landingDispatch({
          type: "updateSearchQuery",
          payload: searchQuery.fields,
        });
        landingDispatch({ type: "showBestSellers", payload: false });
      } else {
        bsFilter.values = [bsFilterField.values[0].value];
        landingDispatch({
          type: "updateSearchQuery",
          payload: searchQuery.fields,
        });
        landingDispatch({ type: "showBestSellers", payload: true });
      }
    } else {
      const fields = [
        ...searchQuery.fields,
        {
          name: "BestSeller",
          values: [bsFilterField.values[0].value],
        },
      ];

      landingDispatch({ type: "updateSearchQuery", payload: fields });
      landingDispatch({ type: "showBestSellers", payload: true });
    }
  }

  useEffect(() => {
    landingDispatch({ type: "setPageLoaded", payload: false });
    landingDispatch({ type: "setItemsLoaded", payload: false });
    api
      .post("catalog/items", {
        category: category,
        filters: [],
      })
      .then((response) => {
        landingDispatch({ type: "setFilters", payload: response.data.groups });
        landingDispatch({ type: "setProducts", payload: response.data.items });
        if (state !== undefined) {
          getFilterValues(state, response.data.groups);
        }
        landingDispatch({ type: "setPageLoaded", payload: true });
        landingDispatch({ type: "setItemsLoaded", payload: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (pageLoaded) {
      landingDispatch({ type: "setItemsLoaded", payload: false });
      api
        .post("catalog/items", {
          category: category,
          filters: searchQuery.fields,
        })
        .then((response) => {
          updateFilterValues(response.data.groups);
          landingDispatch({
            type: "setProducts",
            payload: response.data.items,
          });
          landingDispatch({ type: "setItemsLoaded", payload: true });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <ProductLandingContext.Provider value={{ landingState, landingDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Stack spacing={1} className="sticky-filters" paddingTop={"2rem"}>
            {isMdScreen ? (
              <>
                <Stack justifyContent="center" alignItems="center">
                  {!showFilters ? (
                    <Chip
                      color="primary"
                      label="Show Filters"
                      icon={<ArrowDropDownIcon />}
                      sx={{ paddingLeft: ".5rem" }}
                      onClick={() =>
                        landingDispatch({
                          type: "setShowFilters",
                          payload: true,
                        })
                      }
                    />
                  ) : (
                    <Chip
                      color="primary"
                      label="Hide Filters"
                      icon={<ArrowDropUpIcon />}
                      sx={{ paddingLeft: ".5rem" }}
                      onClick={() =>
                        landingDispatch({
                          type: "setShowFilters",
                          payload: false,
                        })
                      }
                    />
                  )}
                </Stack>
                <Collapse in={showFilters}>
                  <Stack direction="row">
                    {bestSellers ? (
                      <Chip
                        color="success"
                        label="Show All"
                        sx={{ marginRight: ".5rem" }}
                        onClick={() => filterBestSellers()}
                      />
                    ) : (
                      <Chip
                        color="primary"
                        variant="outlined"
                        label="Show Only Best Sellers"
                        sx={{ marginRight: ".5rem" }}
                        onClick={() => filterBestSellers()}
                      />
                    )}
                    <Chip
                      color="warning"
                      label="Reset Filters"
                      sx={{ paddingLeft: ".5rem" }}
                      onClick={() => {
                        landingDispatch({
                          type: "updateSearchQuery",
                          payload: [],
                        });
                        landingDispatch({ type: "setClearAll", payload: true });
                      }}
                    />
                  </Stack>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(6, 1fr)",
                        xl: "repeat(6, 1fr)",
                      },
                    }}
                  >
                    {filters &&
                      filters.map(
                        (filter) =>
                          filter.name !== "BestSeller" && (
                            <ProductFilter
                              filter={filter}
                              key={filter.name}
                              state={state}
                            />
                          )
                      )}
                  </Box>
                </Collapse>
              </>
            ) : (
              <>
                <Grid container sx={{ paddingTop: "3rem" }} spacing={1}>
                  <Grid item xs={6} md={8} lg={10}>
                    <Typography variant="h1">{category}</Typography>
                  </Grid>
                  {!isMill && (
                    <>
                      <Grid item xs={3} md={2} lg={1}>
                        <Chip
                          className="float-right"
                          color="warning"
                          label="Reset Filters"
                          onClick={() => {
                            landingDispatch({
                              type: "updateSearchQuery",
                              payload: [],
                            });
                            landingDispatch({
                              type: "setClearAll",
                              payload: true,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={3} md={2} lg={1}>
                        {filters &&
                          filters.map((filter) => (
                            <React.Fragment key={filter.name}>
                              {filter.name === "BestSeller" && (
                                <>
                                  {bestSellers ? (
                                    <Chip
                                      className="float-right"
                                      color="success"
                                      label="Show All"
                                      sx={{ marginRight: ".5rem" }}
                                      onClick={() => filterBestSellers()}
                                    />
                                  ) : (
                                    <Chip
                                      className="float-right"
                                      color="primary"
                                      variant="outlined"
                                      label="Show Only Best Sellers"
                                      sx={{ marginRight: ".5rem" }}
                                      onClick={() => filterBestSellers()}
                                    />
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          ))}
                      </Grid>
                    </>
                  )}
                </Grid>
                {!isMill && (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(6, 1fr)",
                        xl: "repeat(6, 1fr)",
                      },
                    }}
                  >
                    {filters &&
                      filters.map(
                        (filter) =>
                          filter.name !== "BestSeller" && (
                            <ProductFilter
                              filter={filter}
                              key={filter.name}
                              state={state}
                            />
                          )
                      )}
                  </Box>
                )}
              </>
            )}
          </Stack>
          {!itemsLoaded ? (
            <LoadingIndicator />
          ) : (
            <Grid container spacing={2}>
              {products
                ? products.map((data, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <ProductTiles data={data} category={category} />
                    </Grid>
                  ))
                : "No Products to List, please try different filters"}
            </Grid>
          )}
        </>
      )}
    </ProductLandingContext.Provider>
  );
}
