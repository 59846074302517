// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useState, useEffect, useContext } from "react";
import ReactMarkdown from "react-markdown";
import { Link as DomLink, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import LanguageIcon from "@mui/icons-material/Language";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Link,
  AppBar,
  Toolbar,
  Tooltip,
  Typography,
  Grid,
  Stack,
  Collapse,
  useScrollTrigger,
  Drawer,
  Skeleton,
  Paper,
  InputBase,
  IconButton,
} from "@mui/material";

// Local modules
import { AppContext, CartContext } from "../../utils/stateHandlers/contexts";
import { Default, Mobile } from "../../utils/deviceHelpers";
import formatting from "../../utils/formatHelpers";
import { api } from "../../utils";
import storage from "../../utils/storageFactory";
import GALink from "../../utils/GAComponents/GALink";
import logo from "../../assets/images/HS-LogoOnly.png";
import smallLogo from "../../assets/images/icons/HS-icon.svg";
import Navigation from "./Navigation";
import CustomMetalMarkets from "./CustomMetalMarkets";
import CareersBanner from "../pages/careers/CareersBanner";

export default function Header(props) {
  const {
    showCustomMetalMarkets,
    setShowCustomMetalMarkets,
    user,
    showMetalMarkets,
    setShowMetalMarkets,
    setShowNotificationBanner,
  } = useContext(AppContext);
  const { cartState } = useContext(CartContext);
  const { cartCount, cartLoaded } = cartState;
  const [showCareersBanner, setShowCareersBanner] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchField, setShowSearchField] = useState(false);
  const [metalBase, setMetalBase] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [dateString, setDateString] = useState("");
  const [notificationBanner, setNotificationBanner] = useState();
  const [showDiamondBanner, setShowDiamondBanner] = useState(false);
  let location = useLocation();

  const loggedIn = storage.getStorageItem("authToken");

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    //threshold: 200,
    //target: document.getElementById("end-of-header"),
  });

  const getBackgroundColor = (color) => {
    switch (color) {
      case "red":
        return "#954738";
      case "white":
        return "white";
      case "blue":
        return "#124760";
      case "yellow":
        return "#ecd391";
      case "gold":
        return "#B09862";
      case "darkGreen":
        return "#313E0B";
      case "darkRed":
        return "#753C2C";
      case "darkBlue":
        return "#092532";
      case "gray":
        return "#5B5B5B";
      default:
        return "white";
    }
  };

  const getFontColor = (color) => {
    switch (color) {
      case "red":
        return "#954738";
      case "white":
        return "white";
      case "blue":
        return "#124760";
      case "yellow":
        return "#ecd391";
      case "gold":
        return "#e0b749";
      default:
        return "#333";
    }
  };

  const marketList = () => {
    return (
      <>
        <Typography variant="metalMarkets" color="primary.contrastText">
          Gold {metalBase.gold && formatting["price2"](metalBase.gold)}
        </Typography>
        <Typography variant="metalMarkets" color="primary.contrastText">
          Silver {metalBase.gold && formatting["price2"](metalBase.silver)}
        </Typography>
        <Typography variant="metalMarkets" color="primary.contrastText">
          Platinum {metalBase.gold && formatting["price2"](metalBase.platinum)}
        </Typography>
        <Typography variant="metalMarkets" color="primary.contrastText">
          Palladium{" "}
          {metalBase.gold && formatting["price2"](metalBase.palladium)}
        </Typography>
        <Typography variant="metalMarkets" color="primary.contrastText">
          Iridium {metalBase.gold && formatting["price2"](metalBase.iridium)}
        </Typography>
        <Typography variant="metalMarkets" color="primary.contrastText">
          Ruthenium{" "}
          {metalBase.gold && formatting["price2"](metalBase.ruthenium)}
        </Typography>
      </>
    );
  };

  function redirectToSearch() {
    window.location.replace("/search/" + searchTerm);
  }

  useEffect(() => {
    api.fetch("metalbase/today").then((res) => {
      if (res.data) {
        setMetalBase(res.data);
        setLoaded(true);
        const dateArray = res.data.date.replaceAll("-0", "-").split("-");
        setDateString(`${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const query = qs.stringify({
      populate: {
        background: {
          populate: "*",
        },
        fontcolor: {
          populate: "*",
        },
        notification: {
          populate: "*",
        },
        show: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/notification-banner?${query}`).then((response) => {
      setNotificationBanner(response.data.data.attributes);
      setShowNotificationBanner(
        response.data.data.attributes.show === true ? true : false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === "/careers") {
      setShowCareersBanner(true);
    } else {
      setShowCareersBanner(false);
    }

    if (location.pathname === "/diamonds") {
      setShowDiamondBanner(true);
    } else {
      setShowDiamondBanner(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <header>
      {showCareersBanner ? (
        <CareersBanner />
      ) : (
        <>
          <AppBar>
            <Mobile>
              {notificationBanner && notificationBanner.show && (
                <Grid
                  container
                  direction="row"
                  sx={{
                    backgroundColor: getBackgroundColor(
                      notificationBanner.background
                    ),
                    padding: "0.5rem",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      variant="body1"
                      color={getFontColor(notificationBanner.fontcolor)}
                    >
                      {showDiamondBanner ? (
                        <>
                          <ReactMarkdown>
                            {notificationBanner.notification}
                          </ReactMarkdown>
                          <ReactMarkdown>
                            {notificationBanner.DiamondPageText}
                          </ReactMarkdown>
                        </>
                      ) : (
                        notificationBanner.notification
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Toolbar>
                <Stack width="100%">
                  <Grid container alignItems="center">
                    <Grid item xs={1}>
                      <Typography
                        color="primary.contrastText"
                        onClick={() => setShowMobileMenu(true)}
                      >
                        <MenuIcon sx={{ fontSize: "3rem" }} />
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <DomLink to={{ pathname: "/" }}>
                        <img
                          src={logo}
                          style={{ width: "15rem" }}
                          alt="Hoover and Strong - The Gold Standard"
                        />
                      </DomLink>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        onClick={(x) =>
                          setShowMetalMarkets(showMetalMarkets ? false : true)
                        }
                        variant="body3"
                        className="pointer float-right"
                        color="primary.contrastText"
                      >
                        <AttachMoneyIcon /> Metal Markets
                      </Typography>
                    </Grid>
                  </Grid>
                  <Collapse in={showMetalMarkets}>
                    <Stack
                      spacing={1}
                      justifyContent="center"
                      alignItems="center"
                      sx={{ marginBottom: "2rem" }}
                    >
                      <Typography
                        variant="metalMarkets"
                        color="primary.contrastText"
                      >
                        {`${dateString}`}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="body3"
                          color="primary.contrastText"
                        >
                          Gold{" "}
                          {metalBase.gold &&
                            formatting["price2"](metalBase.gold)}
                        </Typography>
                        <Typography
                          variant="body3"
                          color="primary.contrastText"
                        >
                          Silver{" "}
                          {metalBase.gold &&
                            formatting["price2"](metalBase.silver)}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="body3"
                          color="primary.contrastText"
                        >
                          Platinum{" "}
                          {metalBase.gold &&
                            formatting["price2"](metalBase.platinum)}
                        </Typography>
                        <Typography
                          variant="body3"
                          color="primary.contrastText"
                        >
                          Palladium{" "}
                          {metalBase.gold &&
                            formatting["price2"](metalBase.palladium)}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          variant="body3"
                          color="primary.contrastText"
                        >
                          Iridium{" "}
                          {metalBase.gold &&
                            formatting["price2"](metalBase.iridium)}
                        </Typography>
                        <Typography
                          variant="body3"
                          color="primary.contrastText"
                        >
                          Ruthenium{" "}
                          {metalBase.gold &&
                            formatting["price2"](metalBase.ruthenium)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Collapse>
                </Stack>
                <Drawer
                  anchor="bottom"
                  open={showMobileMenu}
                  onClose={() => setShowMobileMenu(false)}
                >
                  <Navigation
                    showMobileMenu={showMobileMenu}
                    setShowMobileMenu={setShowMobileMenu}
                  />
                </Drawer>
                <Drawer
                  anchor="bottom"
                  open={showSearchField}
                  onClose={() => setShowSearchField(false)}
                >
                  <Stack
                    direction="row"
                    sx={{ marginBottom: "1rem" }}
                    alignItems="center"
                  >
                    <Paper
                      sx={{
                        borderRadius: "0",
                        display: "flex",
                        alignItems: "center",
                        width: 300,
                      }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Hoover & Strong..."
                        onKeyDown={(e) =>
                          e.key === "Enter" && redirectToSearch()
                        }
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        onClick={() => redirectToSearch()}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Stack>
                </Drawer>
              </Toolbar>
            </Mobile>
            {!trigger ? (
              <>
                <Default>
                  {notificationBanner && notificationBanner.show && (
                    <Grid
                      container
                      direction="row"
                      sx={{
                        backgroundColor: getBackgroundColor(
                          notificationBanner.background
                        ),
                        padding: "0.5rem",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          align="center"
                          variant="body1"
                          color={getFontColor(notificationBanner.fontcolor)}
                        >
                          {showDiamondBanner ? (
                            <>
                              <ReactMarkdown>
                                {notificationBanner.notification}
                              </ReactMarkdown>
                              <ReactMarkdown linkTarget="_blank">
                                {notificationBanner.DiamondPageText}
                              </ReactMarkdown>
                            </>
                          ) : (
                            notificationBanner.notification
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Toolbar>
                    <Grid container>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Collapse in={showMetalMarkets}>
                            {loaded && (
                              <Default>
                                <Stack
                                  direction="row"
                                  spacing={5}
                                  sx={{
                                    marginTop: "0.5rem",
                                    marginBottom: "-1rem",
                                  }}
                                  alignItems="flex-end"
                                  justifyContent="flex-end"
                                >
                                  <Typography
                                    variant="metalMarkets"
                                    color="primary.contrastText"
                                  >{`${dateString} METAL MARKETS:`}</Typography>
                                  {marketList()}
                                  <DomLink
                                    to={{
                                      pathname:
                                        "/links/How-The-Metal-Market-Prices-Are-Set",
                                    }}
                                  >
                                    <Typography
                                      variant="metalMarkets"
                                      color="primary.contrastText"
                                    >
                                      Learn More
                                    </Typography>
                                  </DomLink>
                                  <div
                                    className="metal-market-close pointer"
                                    onClick={() => setShowMetalMarkets(false)}
                                  >
                                    <CancelPresentationIcon
                                      sx={{ verticalAlign: "middle" }}
                                    />
                                  </div>
                                </Stack>
                              </Default>
                            )}
                          </Collapse>
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Grid item xs={2}>
                          <Tooltip
                            title="Hoover and Strong is an eco-friendly refiner and manufacturer of precious metals. We offer many products and services that fall under our Harmony brand, including loose diamonds, bridal jewelry, findings, mill products, custom casting and refining."
                            placement="right-end"
                          >
                            <DomLink to="">
                              <img
                                src={logo}
                                alt="Hoover and Strong - The Gold Standard"
                              />
                            </DomLink>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                          {showCustomMetalMarkets && (
                            <CustomMetalMarkets
                              setShowCustomMetalMarkets={
                                setShowCustomMetalMarkets
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={7}>
                          <Stack spacing={1}>
                            <Stack
                              direction="row"
                              spacing={5}
                              justifyContent="flex-end"
                              alignItems="center"
                              sx={{ marginTop: "2rem" }}
                            >
                              <Tooltip title="Call to Order" placement="bottom">
                                <Link
                                  href="tel:1-800-759-9997"
                                  variant="body3"
                                  color="primary.contrastText"
                                  underline="hover"
                                >
                                  <PhoneIcon className="flip-horizontally" />
                                  1-800-759-9997
                                </Link>
                              </Tooltip>
                              <GALink
                                gaEventName="contact_us_link_click"
                                pathTo="/contact"
                                textVariant="body3"
                                textColor="primary.contrastText"
                                underline="hover"
                                linkIcon={<EmailIcon />}
                                linkText="Contact Us"
                              />
                              {/* <DomLink to="/contact">
                                <Typography
                                  variant="body3"
                                  color="primary.contrastText"
                                  underline="hover"
                                >
                                  <EmailIcon /> Contact Us
                                </Typography>
                              </DomLink> */}
                              <DomLink
                                to={{
                                  pathname: "/faq/Website-Help",
                                }}
                              >
                                <Typography
                                  variant="body3"
                                  color="primary.contrastText"
                                  underline="hover"
                                >
                                  <InfoIcon /> Info
                                </Typography>
                              </DomLink>
                              <DomLink to="/careers">
                                <Typography
                                  variant="body3"
                                  color="primary.contrastText"
                                  underline="hover"
                                >
                                  <LanguageIcon /> Careers
                                </Typography>
                              </DomLink>
                              <Typography
                                onClick={(x) => setShowMetalMarkets(true)}
                                variant="body3"
                                color="primary.contrastText"
                                className="pointer"
                              >
                                <AttachMoneyIcon /> Metal Markets
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={5}
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              {!loggedIn ? (
                                <DomLink
                                  to={{
                                    pathname: "/login",
                                  }}
                                >
                                  <Typography
                                    variant="body3"
                                    color="primary.contrastText"
                                    underline="hover"
                                  >
                                    <PersonIcon /> LOG IN/CREATE ACCOUNT
                                  </Typography>
                                </DomLink>
                              ) : (
                                <>
                                  <Tooltip
                                    title="View Your Cart"
                                    placement="bottom"
                                  >
                                    {cartLoaded ? (
                                      <DomLink
                                        to={{
                                          pathname: "/cart",
                                        }}
                                      >
                                        <Typography
                                          variant="body3"
                                          color="primary.contrastText"
                                          underline="hover"
                                        >
                                          <ShoppingCartSharpIcon /> MY CART{" "}
                                          {cartCount > 0 && `(${cartCount})`}
                                        </Typography>
                                      </DomLink>
                                    ) : (
                                      <Skeleton
                                        variant="text"
                                        width="10rem"
                                        className="float-right"
                                      />
                                    )}
                                  </Tooltip>
                                  <Link
                                    onClick={() => user.signOut()}
                                    variant="body3"
                                    color="primary.contrastText"
                                    underline="none"
                                    className="pointer"
                                  >
                                    <PersonIcon /> LOG OUT
                                  </Link>
                                  <DomLink
                                    to={{
                                      pathname: "/account",
                                    }}
                                  >
                                    <Typography
                                      variant="body3"
                                      color="primary.contrastText"
                                      underline="hover"
                                    >
                                      <PersonIcon /> MY ACCOUNT
                                    </Typography>
                                  </DomLink>
                                </>
                              )}
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              spacing={6}
                            >
                              <Paper
                                sx={{
                                  borderRadius: "0",
                                  display: "flex",
                                  alignItems: "center",
                                  width: 300,
                                }}
                              >
                                <InputBase
                                  sx={{ ml: 1, flex: 1 }}
                                  placeholder="Search Hoover & Strong..."
                                  onKeyDown={(e) =>
                                    e.key === "Enter" && redirectToSearch()
                                  }
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                />
                                <IconButton
                                  type="button"
                                  sx={{ p: "10px" }}
                                  onClick={() => redirectToSearch()}
                                >
                                  <SearchIcon />
                                </IconButton>
                              </Paper>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </Default>
                <Default>
                  <Navigation />
                </Default>
              </>
            ) : (
              <>
                <Default>
                  <Toolbar>
                    <Grid container>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Collapse in={showMetalMarkets}>
                            {loaded && (
                              <Default>
                                <Stack
                                  direction="row"
                                  spacing={5}
                                  sx={{
                                    marginTop: "0.5rem",
                                    marginBottom: "-1rem",
                                  }}
                                  alignItems="flex-end"
                                  justifyContent="flex-end"
                                >
                                  <Typography
                                    variant="metalMarkets"
                                    color="primary.contrastText"
                                  >{`${dateString} METAL MARKETS:`}</Typography>
                                  {marketList()}
                                  <Typography
                                    variant="metalMarkets"
                                    color="primary.contrastText"
                                  >
                                    <DomLink
                                      to={{
                                        pathname:
                                          "/links/How-The-Metal-Market-Prices-Are-Set",
                                      }}
                                    >
                                      Learn More
                                    </DomLink>
                                  </Typography>
                                  <div
                                    className="metal-market-close"
                                    onClick={() => setShowMetalMarkets(false)}
                                  >
                                    <CancelPresentationIcon
                                      sx={{ verticalAlign: "middle" }}
                                    />
                                  </div>
                                </Stack>
                              </Default>
                            )}
                          </Collapse>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={1}>
                          <Tooltip
                            title="Hoover and Strong is an eco-friendly refiner and manufacturer of precious metals. We offer many products and services that fall under our Harmony brand, including loose diamonds, bridal jewelry, findings, mill products, custom casting and refining."
                            placement="right-end"
                          >
                            <DomLink to="">
                              <img
                                src={smallLogo}
                                width="30"
                                style={{ margin: "1rem" }}
                                alt="Hoover and Strong - The Gold Standard"
                              />
                            </DomLink>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={11}>
                          <Stack
                            direction="row"
                            spacing={5}
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tooltip title="Call to Order" placement="bottom">
                              <Link
                                href="tel:1-800-759-9997"
                                variant="body3"
                                color="primary.contrastText"
                                underline="hover"
                              >
                                <PhoneIcon className="flip-horizontally" />
                                1-800-759-9997
                              </Link>
                            </Tooltip>
                            <DomLink to="/contact">
                              <Typography
                                variant="body3"
                                color="primary.contrastText"
                                underline="hover"
                              >
                                <EmailIcon /> Contact Us
                              </Typography>
                            </DomLink>
                            <Typography
                              onClick={(x) => setShowMetalMarkets(true)}
                              variant="body3"
                              className="pointer"
                              color="primary.contrastText"
                            >
                              <AttachMoneyIcon /> Metal Markets
                            </Typography>
                            {!props.loggedIn ? (
                              <DomLink
                                to={{
                                  pathname: "/login",
                                }}
                              >
                                <Typography
                                  variant="body3"
                                  color="primary.contrastText"
                                  underline="hover"
                                >
                                  <PersonIcon /> LOG IN/CREATE ACCOUNT
                                </Typography>
                              </DomLink>
                            ) : (
                              <>
                                <Tooltip
                                  title="View Your Cart"
                                  placement="bottom"
                                >
                                  <DomLink
                                    to={{
                                      pathname: "/cart",
                                    }}
                                  >
                                    <Typography
                                      variant="body3"
                                      color="primary.contrastText"
                                      underline="hover"
                                    >
                                      <ShoppingCartSharpIcon /> MY CART{" "}
                                      {cartCount > 0 && `(${cartCount})`}
                                    </Typography>
                                  </DomLink>
                                </Tooltip>
                                <Link
                                  onClick={() => user.signOut()}
                                  variant="body3"
                                  color="primary.contrastText"
                                  underline="none"
                                  className="pointer"
                                >
                                  <PersonIcon /> LOG OUT
                                </Link>
                                <DomLink
                                  to={{
                                    pathname: "/account",
                                  }}
                                >
                                  <Typography
                                    variant="body3"
                                    color="primary.contrastText"
                                    underline="hover"
                                  >
                                    <PersonIcon /> MY ACCOUNT
                                  </Typography>
                                </DomLink>
                              </>
                            )}
                            <Paper
                              sx={{
                                borderRadius: "0",
                                display: "flex",
                                alignItems: "center",
                                width: 300,
                              }}
                            >
                              <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search Hoover & Strong..."
                                onKeyDown={(e) =>
                                  e.key === "Enter" && redirectToSearch()
                                }
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <IconButton
                                type="button"
                                sx={{ p: "10px" }}
                                onClick={() => redirectToSearch()}
                              >
                                <SearchIcon />
                              </IconButton>
                            </Paper>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </Default>
                <Default>
                  <Navigation />
                </Default>
              </>
            )}
          </AppBar>
          <Toolbar />
          <Toolbar />
        </>
      )}
    </header>
  );
}
