import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { Link as DomLink } from "react-router-dom";

const EarringsTab = ({ links }) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <Stack direction="column" className="navbar-stack">
          <Typography className="navbar-text">Shop by Metal Color</Typography>
          {links.EarringsLinks.MetalColor.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Earrings",
                state: {
                  Material: link.materials,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Earrings") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
          <Typography className="navbar-text" marginTop={"2rem"}>
            Shop by Post Style
          </Typography>
          {links.EarringsLinks.EarringsPost.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Earrings",
                state: {
                  PostStyle: link.type,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Earrings") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Typography className="navbar-text">Shop by Shape</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.EarringsLinks.EarringsShape.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Earrings",
                state: {
                  Shape: link.shape,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Earrings") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="column" className="navbar-stack">
          <Typography className="navbar-text">Shop by Prong</Typography>
          {links.EarringsLinks.EarringsProngs.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Earrings",
                state: {
                  Prongs: link.type,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Earrings") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
          <Typography className="navbar-text" marginTop={"2rem"}>
            Shop by Advanced Options
          </Typography>
          {links.EarringsLinks.EarringsAdvanceOptions.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Earrings",
                state: {
                  AdvancedOptions: link.type,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Earrings") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Typography className="navbar-text">Additional Info</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.FindingsInfo.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: link.path,
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
        <Typography className="navbar-text">Printed Materials</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.FindingsMaterial.map((link, index) => (
            <a key={index} href={link.path} target="_blank" rel="noreferrer">
              {link.label}
            </a>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default EarringsTab;
