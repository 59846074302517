import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { Link as DomLink } from "react-router-dom";

const SolitairesTab = ({ links }) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <Stack direction="column" className="navbar-stack">
          <Typography className="navbar-text">Shop by Metal Color</Typography>
          {links.SolitaireLinks.MetalColor.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Solitaires",
                state: {
                  Material: link.materials,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Solitaires") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
          <Typography className="navbar-text" marginTop={"2rem"}>
            Shop by Style Type
          </Typography>
          {links.SolitaireLinks.SolitairesStyle.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Solitaires",
                state: {
                  Style: link.type,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Solitaires") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Typography className="navbar-text">Shop by Shape</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.SolitaireLinks.SolitairesShape.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Solitaires",
                state: {
                  CenterStoneShape: link.shape,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Solitaires") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
          <Typography className="navbar-text" marginTop={"2rem"}>
            Shop by Fit Type
          </Typography>
          {links.SolitaireLinks.SolitairesFit.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Solitaires",
                state: {
                  FitType: link.type,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Solitaires") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="column" className="navbar-stack">
          <Typography className="navbar-text">Shop by Prong</Typography>
          {links.SolitaireLinks.SolitairesProngs.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Solitaires",
                state: {
                  Prongs: link.type,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Solitaires") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
          <Typography className="navbar-text" marginTop={"2rem"}>
            Shop by Advanced Options
          </Typography>
          {links.SolitaireLinks.SolitairesAdvanceOptions.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Solitaires",
                state: {
                  AdvancedOptions: link.type,
                },
              }}
              onClick={() => {
                if (window.location.pathname === "/products/Solitaires") {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Typography className="navbar-text">Additional Info</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.FindingsInfo.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: link.path,
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
        <Typography className="navbar-text">Printed Materials</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.FindingsMaterial.map((link, index) => (
            <a key={index} href={link.path} target="_blank" rel="noreferrer">
              {link.label}
            </a>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SolitairesTab;
