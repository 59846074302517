import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { Link as DomLink } from "react-router-dom";

const BuildARingTab = ({ links }) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <Stack direction="column" className="navbar-stack">
          <Typography className="navbar-text">Build Your Own Ring</Typography>
          <DomLink
            to={{
              pathname: "/ring/build",
            }}
          >
            Start with a Setting
          </DomLink>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Typography className="navbar-text">Additional Info</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.FindingsInfo.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: link.path,
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
        <Typography className="navbar-text">Printed Materials</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.FindingsMaterial.map((link, index) => (
            <a key={index} href={link.path} target="_blank" rel="noreferrer">
              {link.label}
            </a>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BuildARingTab;
