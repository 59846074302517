// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useReducer, useEffect, useContext } from "react";
import { Link as DomLink } from "react-router-dom";
import {
  Container,
  Typography,
  Backdrop,
  CircularProgress,
  Grid,
  Divider,
  Stack,
  Chip,
} from "@mui/material";

// Local modules
import { api, storage } from "../../../utils";
import {
  AppContext,
  BandsContext,
} from "../../../utils/stateHandlers/contexts";
import states from "../../../utils/stateHandlers/initialStates";
import { bandsReducer } from "../../../utils/stateHandlers/reducers";
import formatting from "../../../utils/formatHelpers";
import { functions, constants } from "../../../utils";
import BandsPrice from "../components/bandsComponents/BandsPrice";
import BandsFields from "../components/bandsComponents/BandsFields";
import BandsDetailsTable from "../components/bandsComponents/BandsDetailsTable";
import BandsSpecialInstructions from "../components/bandsComponents/BandsSpecialInstructions";
import BandsTechnicalArticles from "../components/bandsComponents/BandsTechnicalArticles";
import CustomerTestimonials from "../components/CustomerTestimonials";
import ProductGalleryViewer from "../components/ProductGalleryViewer";
import filterAds from "../../../utils/helperFunction";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";
import RecentlyView from "./RecentlyView";
import RelatedItems from "./RelatedItems";

export default function Bands(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const { authToken } = useContext(AppContext);
  const [bandsState, bandsDispatch] = useReducer(
    bandsReducer,
    states.initialBandsState
  );
  const {
    pageLoaded,
    productInfo,
    style,
    images,
    icons,
    productFields,
    builder,
    complex,
    complex1,
    complex2,
    complex3,
    relatedProducts,
    advertisement,
  } = bandsState;
  const productCode = props.match.params.ProductStyleCode;
  const twoPartStyleCode = `${props.match.params.ProductStyleCode}/${props.match.params.ProductStyleCodeSecondPart}`;
  const threePartStyleCode = `${props.match.params.ProductStyleCode}/${props.match.params.ProductStyleCodeSecondPart}/${props.match.params.ProductStyleCodeThirdPart}`;

  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  function setDefaultBandsFormData(band) {
    var defaults = {};
    band.fields.forEach(function (field) {
      if (
        field.template === "field_with_units" &&
        field.name !== "builder[product]"
      ) {
        bandsDispatch({
          type: "setBandDefaults",
          field: field.name,
          payload: field.default,
        });
        bandsDispatch({
          type: "setBandDefaults",
          field: "builder[quantity_unit]",
          payload: "EA",
        });
        defaults = {
          ...defaults,
          [field.name]: field.default,
          "builder[quantity_unit]": "EA",
        };
      } else if (field.name !== "builder[product]") {
        bandsDispatch({
          type: "setBandDefaults",
          field: field.name,
          payload: field.default,
        });
        defaults = {
          ...defaults,
          [field.name]: field.default,
        };
      }
    });
    bandsDispatch({
      type: "setBandDefaults",
      field: "builder[product]",
      payload: band.product,
    });
    bandsDispatch({
      type: "setBandDefaults",
      field: "builder[dimension]",
      payload: band.dimension,
    });
    defaults = {
      ...defaults,
      "builder[product]": band.product,
      "builder[dimension]": band.dimension,
    };
    return defaults;
  }

  function sortBandFields(fields) {
    const builderArray = [];
    const complexArray = [];
    const complex1Array = [];
    const complex2Array = [];
    const complex3Array = [];
    fields.forEach(function (field) {
      if (field.name.startsWith("builder")) {
        builderArray.push(field);
      } else if (field.name.startsWith("complex[1]")) {
        complex1Array.push(field);
      } else if (field.name.startsWith("complex[2]")) {
        complex2Array.push(field);
      } else if (field.name.startsWith("complex[3]")) {
        complex3Array.push(field);
      } else {
        complexArray.push(field);
      }
    });
    const sortedComplex1Array = addSortOrderToFields(complex1Array);
    const sortedComplex2Array = addSortOrderToFields(complex2Array);
    const sortedComplex3Array = addSortOrderToFields(complex3Array);
    bandsDispatch({
      type: "setBandFieldCategories",
      payload: {
        builder: builderArray,
        complex: complexArray,
        complex1: sortedComplex1Array,
        complex2: sortedComplex2Array,
        complex3: sortedComplex3Array,
      },
    });
  }

  function addSortOrderToFields(fields) {
    const newSortedFields = [];
    fields.forEach(function (field) {
      const newField = {
        ...field,
        SortOrder: field.name.endsWith("[material]")
          ? 1
          : field.name.endsWith("[width]")
          ? 2
          : field.name.endsWith("[finish]")
          ? 3
          : field.name.endsWith("[rhodium]")
          ? 4
          : field.name.endsWith("[pattern]")
          ? 5
          : field.name.endsWith("[type]")
          ? 6
          : field.name.endsWith("[source]")
          ? 7
          : field.name.endsWith("[quality]")
          ? 8
          : field.name.endsWith("[size]")
          ? 9
          : field.name.endsWith("[quantity]")
          ? 10
          : 100,
      };
      newSortedFields.push(newField);
    });

    return newSortedFields.sort((a, b) => a.SortOrder - b.SortOrder);
  }

  function getInitialPrice(bandDefaults) {
    const query = qs.stringify(bandDefaults);
    if (authToken) {
      api.fetchBandPrice(`quickorder/price`, query).then((response) => {
        bandsDispatch({ type: "setPrice", payload: response.data });
        bandsDispatch({ type: "setPriceLoaded", payload: true });
      });
    }
  }

  function getPrice() {
    const query = qs.stringify(productInfo);
    if (authToken) {
      api.fetchBandPrice(`quickorder/price`, query).then((response) => {
        bandsDispatch({ type: "updatePrice", payload: response.data });
        bandsDispatch({ type: "setPriceLoaded", payload: true });
        updateFields(response.data.fields);
      });
    }
  }

  function updateFields(fields) {
    const newFields = [];
    productFields.forEach((field) => {
      newFields.push(
        field.name.endsWith("[material]") ||
          field.name.endsWith("[width]") ||
          field.name.endsWith("[size]")
          ? field
          : null
      );
    });
    fields.forEach(function (field) {
      newFields.push(
        field.name.endsWith("[material]") ||
          field.name.endsWith("[width]") ||
          field.name.endsWith("[size]")
          ? null
          : field
      );
    });
    const filteredFields = Array.from(
      new Set(newFields.filter((field) => field !== null))
    );
    sortBandFields(filteredFields);
  }

  useEffect(() => {
    bandsDispatch({ type: "setPageLoaded", payload: false });
    bandsDispatch({ type: "setPriceLoaded", payload: false });
    api
      .fetch(
        `quickorder/form?style=${
          props.match.params.ProductStyleCodeThirdPart
            ? threePartStyleCode
            : props.match.params.ProductStyleCodeSecondPart
            ? twoPartStyleCode
            : productCode
        }`
      )
      .then((response) => {
        const bandsDefaults = setDefaultBandsFormData(response.data);
        sortBandFields(response.data.fields);
        bandsDispatch({ type: "setBandInfo", payload: response.data });
        functions.storeRecentlyViewed(response.data);
        bandsDispatch({ type: "setPageLoaded", payload: true });
        getInitialPrice(bandsDefaults);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.ProductStyleCode]);

  useEffect(() => {
    if (authToken && pageLoaded) {
      bandsDispatch({ type: "setPriceLoaded", payload: false });
      getPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  //advertisements from Strapi
  useEffect(() => {
    const query = qs.stringify({
      populate: {
        One: {
          populate: "*",
        },
        Two: {
          populate: "*",
        },
        Three: {
          populate: "*",
        },
        Four: {
          populate: "*",
        },
        Five: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/detail-page-ad?${query}`).then((response) => {
      const ads = response.data.data.attributes;
      const randomAd = filterAds(ads);

      bandsDispatch({
        type: "setPageAd",
        payload: randomAd,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.ProductStyleCode]);

  return (
    <BandsContext.Provider value={{ bandsState, bandsDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl">
          {isMdScreen ? (
            <>
              <Stack spacing={1} sx={{ marginBottom: ".5rem" }}>
                <Typography
                  variant="h4"
                  textAlign={"center"}
                  marginTop={"4rem"}
                >
                  {style}
                </Typography>
                <Typography textAlign={"center"}>
                  {productInfo.description &&
                    formatting["decodeHtml"](productInfo.description)}
                </Typography>
                <Stack direction="row" spacing={1} justifyContent={"center"}>
                  {icons &&
                    icons.map((icons, key) => (
                      <Typography key={icons} className="icons">
                        {functions.getIcons(icons)}
                      </Typography>
                    ))}
                </Stack>
              </Stack>
              <Divider />
            </>
          ) : null}
          <Grid container spacing={6} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={12} md={5}>
              <Stack>
                <ProductGalleryViewer images={images} />
                <BandsDetailsTable />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ marginBottom: ".5rem" }}>
                <Typography variant="h4" marginTop={"4rem"}>
                  {style}
                </Typography>
                <Typography>
                  {productInfo.description &&
                    formatting["decodeHtml"](productInfo.description)}
                </Typography>
                <Stack direction="row" spacing={1}>
                  {icons &&
                    icons.map((icons, key) => (
                      <Typography key={icons} className="icons">
                        {functions.getIcons(icons)}
                      </Typography>
                    ))}
                </Stack>
              </Stack>
              <Stack spacing={2}>
                <Divider flexItem sx={{ marginBottom: "2rem" }}>
                  <Chip
                    label={<Typography variant="h6">General</Typography>}
                    sx={{ backgroundColor: "unset" }}
                  />
                </Divider>
                {builder &&
                  builder.map((field) => {
                    return (
                      <BandsFields
                        field={field}
                        key={`${field.name}${field.default}`}
                      />
                    );
                  })}
                {complex &&
                  complex.map((field) => {
                    return (
                      <BandsFields
                        field={field}
                        key={`${field.name}${field.default}`}
                      />
                    );
                  })}
                <Divider flexItem sx={{ marginBottom: "2rem" }}>
                  <Chip
                    label={<Typography variant="h6">Part One</Typography>}
                    sx={{ backgroundColor: "unset" }}
                  />
                </Divider>
                {complex1 &&
                  complex1.map((field) => {
                    return (
                      <BandsFields
                        field={field}
                        key={`${field.name}${field.default}`}
                      />
                    );
                  })}
                {complex2 && complex2.length > 0 && (
                  <Divider flexItem sx={{ marginBottom: "2rem" }}>
                    <Chip
                      label={<Typography variant="h6">Part Two</Typography>}
                      sx={{ backgroundColor: "unset" }}
                    />
                  </Divider>
                )}

                {complex2 &&
                  complex2.map((field) => {
                    return (
                      <BandsFields
                        field={field}
                        key={`${field.name}${field.default}`}
                      />
                    );
                  })}
                {complex3 && complex3.length > 0 && (
                  <Divider flexItem sx={{ marginBottom: "2rem" }}>
                    <Chip
                      label={<Typography variant="h6">Part Three</Typography>}
                      sx={{ backgroundColor: "unset" }}
                    />
                  </Divider>
                )}

                {complex3 &&
                  complex3.map((field) => {
                    return (
                      <BandsFields
                        field={field}
                        key={`${field.name}${field.default}`}
                      />
                    );
                  })}
                <BandsSpecialInstructions />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              {advertisement && (
                <DomLink
                  to={{
                    pathname: `${advertisement.alternativeText}`,
                    state: {
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                >
                  <img
                    style={{ marginTop: "4rem" }}
                    src={`${frontImageUrl}${advertisement.url}`}
                    width="100%"
                    alt="advertisement"
                  />
                </DomLink>
              )}
              <BandsPrice />
            </Grid>
          </Grid>
          <RelatedItems relatedProducts={relatedProducts} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <BandsTechnicalArticles />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomerTestimonials />
            </Grid>
          </Grid>
          <RecentlyView />
        </Container>
      )}
    </BandsContext.Provider>
  );
}
