import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { Link as DomLink } from "react-router-dom";

const EarringCompTab = ({ links }) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <Stack direction="column" className="navbar-stack">
          <Typography className="navbar-text">Shop by Metal Color</Typography>
          {links.EarringComponentLink.MetalColor.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Earring And Pendant Components",
                state: {
                  Material: link.materials,
                },
              }}
              onClick={() => {
                if (
                  window.location.pathname ===
                  "/products/Earring And Pendant Components"
                ) {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
          <Typography className="navbar-text" marginTop={"2rem"}>
            Shop by Earring Component Type
          </Typography>
          {links.EarringComponentLink.EarringCompType.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: "/products/Earring And Pendant Components",
                state: {
                  ComponentType: link.type,
                },
              }}
              onClick={() => {
                if (
                  window.location.pathname ===
                  "/products/Earring And Pendant Components"
                ) {
                  window.location.reload();
                }
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="column" className="navbar-stack">
          <Typography className="navbar-text">
            Shop by Advanced Options
          </Typography>
          {links.EarringComponentLink.EarringCompAdvanceOptions.map(
            (link, index) => (
              <DomLink
                key={index}
                to={{
                  pathname: "/products/Earring And Pendant Components",
                  state: {
                    AdvancedOptions: link.type,
                  },
                }}
                onClick={() => {
                  if (
                    window.location.pathname ===
                    "/products/Earring And Pendant Components"
                  ) {
                    window.location.reload();
                  }
                }}
              >
                {link.label}
              </DomLink>
            )
          )}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Typography className="navbar-text">Additional Info</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.FindingsInfo.map((link, index) => (
            <DomLink
              key={index}
              to={{
                pathname: link.path,
              }}
            >
              {link.label}
            </DomLink>
          ))}
        </Stack>
        <Typography className="navbar-text">Printed Materials</Typography>
        <Stack direction="column" className="navbar-stack">
          {links.FindingsMaterial.map((link, index) => (
            <a key={index} href={link.path} target="_blank" rel="noreferrer">
              {link.label}
            </a>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default EarringCompTab;
