const tabs = {
  FindingsTab: [
    { label: "Shanks", value: "1", link: "/products/Shanks" },
    { label: "Settings", value: "2", link: "/products/Settings" },
    { label: "Earrings", value: "3", link: "/products/Earrings" },
    { label: "Pendants", value: "4", link: "/products/Pendants" },
    {
      label: "Earring & Pendant Components",
      value: "5",
      link: "/products/Earring And Pendant Components",
    },
    {
      label: "Chain & Chain Components",
      value: "6",
      link: "/products/Chain and Chain Components",
    },
    {
      label: "More Products",
      value: "7",
    },
  ],
  RingTabs: [
    { label: "Solitaire", value: "1", link: "/products/Solitaires" },
    {
      label: "Engagement Ring",
      value: "2",
      link: "/products/All%20Engagement%20Rings",
    },
    { label: "Build-A-Ring", value: "3", link: "/ring/build" },
  ],
  JewelryTabs: [
    { label: "Signet Rings", value: "1", link: "/products/Signet%20Rings" },
    {
      label: "Finished Diamond Jewelry",
      value: "2",
      link: "/finished-diamond-jewelry",
    },
    { label: "Bracelets", value: "3", link: "/products/Bracelets" },
    {
      label: "Men's Fashion Ring",
      value: "4",
      link: "/products/Men's%20Fashion%20Jewelry",
    },
  ],
};

export default tabs;
