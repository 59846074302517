// Third-party libraries
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { InputLabel, Typography, Stack } from "@mui/material";

// Local modules
import { ProductLandingContext } from "../../../utils/stateHandlers/contexts";

export default function ProductFilter(props) {
  const { landingState, landingDispatch } = useContext(ProductLandingContext);
  const { searchQuery, clearAll } = landingState;
  const [selected, setSelected] = useState([]);
  const filter = props.filter;
  const state = props.state;

  function updateFilters(filterName, filterValues) {
    if (filter.name === filterName) {
      setSelected(filterValues);
      landingDispatch({ type: "setClearAll", payload: false });
      const filterToUpdate = searchQuery.fields.find(
        (field) => field.name === filterName
      );
      const formattedValues = filterValues.map(
        (filterValue) => filterValue.value
      );

      if (filterToUpdate) {
        filterToUpdate.values = formattedValues;
        landingDispatch({
          type: "updateSearchQuery",
          payload: searchQuery.fields,
        });
      } else {
        const fields = [
          ...searchQuery.fields,
          {
            name: filterName,
            values: formattedValues,
          },
        ];

        landingDispatch({ type: "updateSearchQuery", payload: fields });
      }
    }
  }
  useEffect(() => {
    if (clearAll) {
      setSelected(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAll]);

  useEffect(() => {
    if (state !== undefined) {
      let filterName = Object.keys(state)[0];
      let filterValues = state[filterName];
      updateFilters(filterName, filterValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack sx={{ marginRight: "1rem" }} key={filter.name}>
      <InputLabel
        sx={{ marginBottom: ".2rem", marginTop: ".2rem", marginLeft: "1rem" }}
      >
        <Typography variant="navText" sx={{ color: "black" }}>
          {filter.label}
        </Typography>
      </InputLabel>
      <Select
        key={filter.name}
        onChange={(e) => {
          updateFilters(filter.name, e);
        }}
        options={filter.values}
        value={selected}
        isMulti
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#cfdde9",
            primary: "#124760",
          },
        })}
      />
    </Stack>
  );
}
