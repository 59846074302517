// Third-party libraries
import React, { useContext, useReducer, useEffect } from "react";
import { Link as DomLink } from "react-router-dom";
import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";
import PersonIcon from "@mui/icons-material/Person";
import {
  Container,
  Paper,
  Stack,
  Divider,
  Link,
  Typography,
} from "@mui/material";

// Local modules
import { AppContext, CartContext } from "../../utils/stateHandlers/contexts";
import { cartReducer } from "../../utils/stateHandlers/reducers";
import states from "../../utils/stateHandlers/initialStates";
import { storage, api } from "../../utils";
import Header from "./Header";
import Footer from "./Footer";
import useMediaQueryUtils from "../../utils/mediaQueryUtils";

export default function Layout(props) {
  const isMdScreen = useMediaQueryUtils("md");
  let {
    showFooter,
    showHeader,
    user,
    showMetalMarkets,
    showNotificationBanner,
  } = useContext(AppContext);
  const [cartState, cartDispatch] = useReducer(
    cartReducer,
    states.initialCartState
  );
  const { cartCount } = cartState;

  const loggedIn = storage.getStorageItem("authToken");

  const cartId =
    storage.getStorageItem("cartId") !== "null"
      ? storage.getStorageItem("cartId")
      : JSON.parse(storage.getStorageItem("cartId"));

  useEffect(() => {
    if (loggedIn) {
      api.fetch("cart/check?cart_id=", cartId).then((response) => {
        if (response.isAxiosError) {
          cartDispatch({ type: "setCart", payload: [] });
          cartDispatch({ type: "setCartCount", payload: 0 });
        } else {
          cartDispatch({ type: "setCart", payload: response.data.items });
          cartDispatch({ type: "setCartCount", payload: response.data.count });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CartContext.Provider value={{ cartState, cartDispatch }}>
      {showHeader && <Header loggedIn={loggedIn} />}
      <Container
        maxWidth="100rem"
        sx={
          showMetalMarkets && showNotificationBanner
            ? { minHeight: "40rem", marginTop: "14rem" }
            : showMetalMarkets && !showNotificationBanner
            ? { minHeight: "40rem", marginTop: "9rem" }
            : !showMetalMarkets && showNotificationBanner
            ? { minHeight: "40rem", marginTop: "15rem" }
            : { minHeight: "40rem", marginTop: "3rem" }
        }
      >
        {props.children}
      </Container>
      {isMdScreen ? (
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              paddingBottom: "2rem",
              paddingTop: "2rem",
              backgroundColor: "#f0f0f0",
            }}
          >
            {loggedIn ? (
              <>
                <Link
                  onClick={() => {
                    user.signOut();
                  }}
                  variant="body3"
                  underline="none"
                  className="pointer"
                >
                  <PersonIcon /> LOG OUT
                </Link>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: "black" }}
                />
                <DomLink
                  to={{
                    pathname: "/account",
                  }}
                >
                  <Typography color="primary" variant="body3" underline="hover">
                    <PersonIcon /> ACCOUNT
                  </Typography>
                </DomLink>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: "black" }}
                />
                <DomLink
                  to={{
                    pathname: "/cart",
                  }}
                >
                  <Typography color="primary" variant="body3" underline="hover">
                    <ShoppingCartSharpIcon /> CART{" "}
                    {cartCount > 0 && `(${cartCount})`}
                  </Typography>
                </DomLink>
              </>
            ) : (
              <DomLink
                to={{
                  pathname: "/login",
                }}
              >
                <Typography color="primary" variant="body3" underline="hover">
                  <PersonIcon /> LOGIN
                </Typography>
              </DomLink>
            )}
          </Stack>
        </Paper>
      ) : null}

      {showFooter && <Footer />}
    </CartContext.Provider>
  );
}
