// Third-party libraries
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link as DomLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";

// Local modules
import {
  DiamondSearchContext,
  CartContext,
} from "../../utils/stateHandlers/contexts";
import { storage, api } from "../../utils";
import formatting from "../../utils/formatHelpers";
import DiamondIconStrip from "./controls/DiamondIconStrip";
import Modal from "./controls/Modal";
import CartModal from "../user/CartModal";
import debounce from "../../utils/debounce";

export default function CertDiamondSingleAddToCartPreview(props) {
  const { diamondState, dispatch } = useContext(DiamondSearchContext);
  const { cartPreviewSingle, openCartPreviewSingle } = diamondState;
  const { cartDispatch } = useContext(CartContext);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [showOFCHelp, setShowOFCHelp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [terms, setTerms] = useState(false);
  const [openCartModal, setOpenCartModal] = useState(false);
  const [errorMessages] = useState([]);

  const grandTotal = cartPreviewSingle.HooverPrice;

  function markConditionalSale(checked) {
    cartPreviewSingle.conditionalSale = checked;
  }

  function addToCart() {
    if (!terms) {
      setShowAlert(true);
    } else {
      const addToCartBody = {
        cart_id:
          storage.getStorageItem("cartId") === "null" ||
          storage.getStorageItem("cartId") === "undefined"
            ? localStorage.removeItem("cartId")
            : storage.getStorageItem("cartId"),
        partcode: {
          material: "D",
          product: cartPreviewSingle.Shape,
          dimension: `${cartPreviewSingle.Color}${cartPreviewSingle.Clarity}`,
          finger_size: parseInt(cartPreviewSingle.HooverStockId),
          quantity: "1",
          quantity_unit: "EA",
          carat_size: cartPreviewSingle.Carat,
          custom_dimension: {
            sale: cartPreviewSingle.conditionalSale ? 0 : 1,
            terms: 1,
          },
          message: cartPreviewSingle.conditionalSale
            ? `Conditional Sale. ${specialInstructions}`
            : specialInstructions,
        },
      };
      api
        .post("cart/add", addToCartBody)
        .then((res) => {
          if (res.isAxiosError) {
            toast.error(
              `There was an error processing your request. ${res.response.data.error.toString()}`
            );
          } else {
            cartDispatch({
              type: "updateCart",
              payload: {
                cart: res.data.items,
                cartCount: res.data.count,
              },
            });
            storage.setStorageItem("cartId", res.data.cart_id);
            setOpenCartModal(true);
            setTerms(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  return (
    <>
      <Dialog
        open={openCartPreviewSingle}
        onClose={() => {
          dispatch({ type: "setOpenCartPreviewSingle", payload: false });
          dispatch({ type: "setShowCartSuccess", payload: false });
        }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch({
                type: "setOpenCartPreviewSingle",
                payload: false,
              });
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="articleTitle">Cart Preview</Typography>
          <Typography variant="subtitle1">
            If you are requesting this diamond on memo, please make sure that
            you select the "Order for Conditional Sale" checkbox.
          </Typography>
          <>
            <Table size="small" sx={{ marginTop: "20px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ position: "unset", fontSize: "1.2rem" }}
                  ></TableCell>
                  <TableCell
                    sx={{ position: "unset", fontSize: "1.2rem" }}
                  ></TableCell>
                  <TableCell sx={{ position: "unset", fontSize: "1.2rem" }}>
                    Price P/C
                  </TableCell>
                  <TableCell sx={{ position: "unset", fontSize: "1.2rem" }}>
                    Total Price
                  </TableCell>
                  <TableCell
                    className="text-align-center"
                    sx={{
                      position: "unset",
                      fontSize: "1.4rem",
                      lineHeight: "1.6rem",
                      fontWeight: "800",
                      fontStyle: "italic",
                      color: "#954738",
                    }}
                  >
                    Order for <br /> Conditional Sale <br />
                    <HelpIcon
                      onClick={() => {
                        setShowOFCHelp(true);
                      }}
                      className="help-icon question-mark"
                    />
                    <Modal
                      title="Loose Diamond Memo Policy:"
                      onClose={() => setShowOFCHelp(false)}
                      show={showOFCHelp}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ position: "unset", fontSize: "1.2rem" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartPreviewSingle && (
                  <TableRow key={cartPreviewSingle.HooverStockId}>
                    <TableCell className="font-size-regular">
                      <Typography variant="cellFont">
                        {`SKU: ${cartPreviewSingle.HooverStockId} ${
                          cartPreviewSingle.MinedOrLab === "M"
                            ? "Mined Diamond"
                            : "Lab-Grown Diamond"
                        }`}
                      </Typography>
                      <br />
                      <Typography variant="cellFont">
                        {`${cartPreviewSingle.Carat}ct ${cartPreviewSingle.Shape} ${cartPreviewSingle.Color} ${cartPreviewSingle.Clarity} ${cartPreviewSingle.HooverCertification}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <DiamondIconStrip
                        diamond={cartPreviewSingle}
                        show_text={true}
                      />
                    </TableCell>
                    <TableCell className="text-align-right font-size-regular">
                      {formatting["price2"](
                        cartPreviewSingle.CalcPricePerCarat || 0
                      )}
                    </TableCell>
                    <TableCell className="text-align-right font-size-regular">
                      {formatting["price2"](cartPreviewSingle.HooverPrice)}
                    </TableCell>
                    <TableCell className="text-align-center">
                      <Checkbox
                        //checked={cartPreviewSingle.conditionalSale}
                        onChange={(e) => markConditionalSale(e.target.checked)}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Stack spacing={2}>
              <Grid container />
              <Grid container>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Typography variant="h3">Grand Total: </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h3" className="float-right">
                    {formatting["price2"](grandTotal)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} />
                <Grid item xs={7}>
                  <FormControlLabel
                    label={
                      <Typography variant="cellFont">
                        I have read and agree to the{" "}
                        <DomLink
                          to={{ pathname: "/links/Company-Policies" }}
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          Terms & Conditions
                        </DomLink>{" "}
                        *
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={terms}
                        onChange={() => setTerms(terms ? false : true)}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} />
                <Grid item xs={7}>
                  <TextField
                    label="Special Instructions"
                    multiline
                    fullWidth
                    rows={3}
                    value={specialInstructions}
                    onChange={(e) => setSpecialInstructions(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} />
                <Grid item xs={7}>
                  <Button
                    className="float-right"
                    variant="contained"
                    color="success"
                    onClick={() => addToCart()}
                  >
                    Add to Cart
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </>
        </DialogContent>
      </Dialog>
      <CartModal
        cartMessage="Your Diamond order has been successfully added to your cart!"
        errors={errorMessages}
        open={openCartModal}
        close={setOpenCartModal}
        product={true}
      />
      <Dialog
        open={showAlert}
        onClose={() => setShowAlert(false)}
        onBackdropClick={() => setShowAlert(false)}
      >
        <DialogTitle sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>
          Terms & Conditions
          <IconButton
            aria-label="close"
            onClick={() => setShowAlert(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography gutterBottom sx={{ fontSize: "1.4rem" }}>
              Please accept the terms and conditions.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
