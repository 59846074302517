// Third-party libraries
import React, { useState, useEffect } from "react";
import { Link as DomLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  AppBar,
  Grid,
  Tab,
} from "@mui/material";
import { TabPanel, TabList, TabContext } from "@mui/lab";
// Local modules
import { Mobile, Default } from "../../utils/deviceHelpers";
import links from "./NavigationLinks";
import tabs from "./Tabs";
import ShanksTab from "./TabPanels/ShanksTab";
import SettingsTab from "./TabPanels/SettingsTab";
import EarringsTab from "./TabPanels/EarringsTab";
import PendantsTab from "./TabPanels/PendantsTab";
import EarringCompTab from "./TabPanels/EarringPendantsComponentsTab";
import ChainCompTab from "./TabPanels/ChainComponentsTab";
import MoreProductsTab from "./TabPanels/MoreProductsTab";
import SolitairesTab from "./TabPanels/SolitairesTab";
import EngagementRingTab from "./TabPanels/EngagementRingTab";
import BuildARingTab from "./TabPanels/BuildARingTab";
import SignetRingsTab from "./TabPanels/SignetRingsTab";
import FinishedDiamondJewelryTab from "./TabPanels/FinishedDiamondJewelryTab";
import BraceletsTab from "./TabPanels/BraceletsTab";
import FashionTab from "./TabPanels/FashionRingTab";
const tabListStyles = {
  "& .MuiTab-root": {
    "&:hover": {
      backgroundColor: "#cad7df",
    },
  },
  "& .MuiTab-root.Mui-selected": {
    color: "black",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "black", // Change underline color for the selected tab
  },
};

export default function Navigation(props) {
  const [value, setValue] = useState("1");
  const [expanded, setExpanded] = useState("");
  const [hoverValue, setHoverValue] = useState("1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabChange = (newValue) => {
    setValue(newValue);
    setHoverValue(newValue);
  };
  const handleHover = (newHoverValue) => {
    setHoverValue(newHoverValue);
    setValue(newHoverValue);
  };

  const MillLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Mill-Products",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Mill Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/GR",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Casting Grain
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/ALL",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Casting Alloy
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/FS",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Flat Sheet
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Wire",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Wire
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Tubing",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Tubing
      </DomLink>
      <DomLink
        to={{
          pathname: "/group/SOLDER",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Solder
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/COINS BULLION",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Coins &amp; Bullion
      </DomLink>
      <DomLink
        to={{
          pathname: "/links/Material-Specification-Sheets",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Material Spec Sheets
      </DomLink>
      <DomLink
        to={{
          pathname: "/links/Safety-Data-Sheets",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Safety Data Sheets
      </DomLink>
      <DomLink
        to={{
          pathname: "/links/Hoover-And-Strong-Metals-Chart",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Metal Charts
      </DomLink>
    </Stack>
  );

  const FindingsLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Findings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Findings Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/All Shanks",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Shanks
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/All Settings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Settings
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Earrings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Earrings
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Pendants",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Pendants
      </DomLink>

      <DomLink
        to={{
          pathname: "/products/Earring and Pendant Components",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Earring and Pendant Components
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Chain and Chain Components",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Chain and Chain Components
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/CIRCLES",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Circles
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Alphabets",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Alphabets
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/WAS",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Washers
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/CHM",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Charms
      </DomLink>
      <DomLink
        to={{
          pathname: "/product/WRB",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Ring Blanks
      </DomLink>
    </Stack>
  );

  const BandsLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Bands",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Bands Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Bands",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        All Bands
      </DomLink>
    </Stack>
  );

  const BridalLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Bridal-Jewelry",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Bridal Jewelry Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Solitaires",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Solitaires
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/All Engagement Rings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Engagement Rings
      </DomLink>
      <DomLink
        to={{
          pathname: "/ring/build",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Build A Ring
      </DomLink>
    </Stack>
  );

  const JewelryLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Finished-Jewelry",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Finished Jewelry Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Signet Rings",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Signet Rings
      </DomLink>
      <DomLink
        to={{
          pathname: "/finished-diamond-jewelry",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Finished Diamond Jewelry
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Bracelets",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Bracelets
      </DomLink>
      <DomLink
        to={{
          pathname: "/products/Men's Fashion Jewelry",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Fashion Rings
      </DomLink>
      <a
        href="https://outlet.hooverandstrong.com"
        target="_blank"
        rel="noreferrer"
      >
        Outlet Store
      </a>
    </Stack>
  );

  const DiamondLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Diamonds",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Diamonds Home
      </DomLink>
      <DomLink
        to={{
          pathname: "/diamonds",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Certified Diamond Search
      </DomLink>
      <DomLink
        to={{
          pathname: "/melee-diamonds",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Melee Diamond Search
      </DomLink>
      <DomLink
        to={{
          pathname: "/harmony-diamond-auctions",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Harmony Diamond Auctions
      </DomLink>
      <DomLink
        to={{
          pathname: "/diamond-services",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Diamond Services
      </DomLink>
      <DomLink
        to={{
          pathname: "/links/Diamond-Info-And-Forms",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Diamond Info and Forms
      </DomLink>
      <DomLink
        to={{
          pathname: "/sub-landing/Gemstones",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Gemstones
      </DomLink>
    </Stack>
  );

  const CastingLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/accordion-landing/Contract-Casting",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Contract Casting Home
      </DomLink>
      <a
        href="http://api2.hooverandstrong.com/pdfs/ContractCastingForm-10-22-19.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Contract Casting Form
      </a>
      <a
        href="http://api2.hooverandstrong.com/pdfs/FedExWaiver.pdf"
        target="_blank"
        rel="noreferrer"
      >
        FedEx Waiver
      </a>
      <DomLink
        to={{
          pathname: "/casting-calculator",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Online Calculator
      </DomLink>
    </Stack>
  );

  const RefiningLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Refining",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Refining Home
      </DomLink>
    </Stack>
  );

  const HarmonyLinks = () => (
    <Stack spacing={1}>
      <DomLink
        to={{
          pathname: "/landing/Harmony",
        }}
        onClick={() => props.setShowMobileMenu(false)}
      >
        Harmony Home
      </DomLink>
    </Stack>
  );
  useEffect(() => {}, [value]);
  return (
    <>
      <Mobile>
        <span className="search-field-container search-options">
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "mill"}
            onChange={handleChange("mill")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">MILL</Typography>
            </AccordionSummary>
            <AccordionDetails>{MillLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "findings"}
            onChange={handleChange("findings")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">FINDINGS</Typography>
            </AccordionSummary>
            <AccordionDetails>{FindingsLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "bands"}
            onChange={handleChange("bands")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">BANDS</Typography>
            </AccordionSummary>
            <AccordionDetails>{BandsLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "bridal"}
            onChange={handleChange("bridal")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">BRIDAL</Typography>
            </AccordionSummary>
            <AccordionDetails>{BridalLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "finished"}
            onChange={handleChange("finished")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">FINISHED JEWELRY</Typography>
            </AccordionSummary>
            <AccordionDetails>{JewelryLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "diamonds"}
            onChange={handleChange("diamonds")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">DIAMONDS AND GEMSTONES</Typography>
            </AccordionSummary>
            <AccordionDetails>{DiamondLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "casting"}
            onChange={handleChange("casting")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">CONTRACT CASTING</Typography>
            </AccordionSummary>
            <AccordionDetails>{CastingLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "refining"}
            onChange={handleChange("refining")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">REFINING</Typography>
            </AccordionSummary>
            <AccordionDetails>{RefiningLinks()}</AccordionDetails>
          </Accordion>
          <Accordion
            square={true}
            disableGutters={true}
            sx={{ width: "100%", minHeight: "unset", paddingRight: "1rem" }}
            expanded={expanded === "harmony"}
            onChange={handleChange("harmony")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">HARMONY</Typography>
            </AccordionSummary>
            <AccordionDetails>{HarmonyLinks()}</AccordionDetails>
          </Accordion>
          <Typography>
            <DomLink
              to={{
                pathname: "/links/How-The-Metal-Market-Prices-Are-Set",
              }}
              onClick={() => props.setShowMobileMenu(false)}
            >
              Learn More About the Metal Markets
            </DomLink>
          </Typography>
        </span>
      </Mobile>
      <Default>
        <AppBar position="static">
          <Stack className="navbar" direction={"row"} spacing={2}>
            <li className="parent">
              <DomLink
                to={{
                  pathname: "/landing/Mill-Products",
                }}
              >
                <Typography variant="navText">Mill</Typography>
              </DomLink>
              <ul>
                <Grid container>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">
                      Shop by Type
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      {links.MillProductLinks.map((link, index) => (
                        <DomLink key={index} to={{ pathname: link.path }}>
                          {link.label}
                        </DomLink>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">
                      Alloy Information
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      {links.AlloyInformation.map((link, index) => (
                        <DomLink key={index} to={{ pathname: link.path }}>
                          {link.label}
                        </DomLink>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Stack direction={"column"} className="navbar-stack">
                      <Typography className="navbar-text">
                        Addional Information
                      </Typography>
                      {links.ResponsibilityLinks.map((link, index) => (
                        <DomLink key={index} to={{ pathname: link.path }}>
                          {link.label}
                        </DomLink>
                      ))}
                      <Typography className="navbar-text" marginTop={"2rem"}>
                        Printed Materials
                      </Typography>
                      {links.MillBrochureLink.map((link, index) => (
                        <a
                          key={index}
                          href={link.path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {link.label}
                        </a>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </ul>
            </li>
            <li className="parent">
              <DomLink
                to={{
                  pathname: "/landing/Findings",
                }}
              >
                <Typography variant="navText">Findings</Typography>
              </DomLink>
              <ul style={{ height: "700px" }}>
                <TabContext value={hoverValue || value}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        borderRight: 1,
                        borderColor: "divider",
                      }}
                    >
                      <TabList
                        onChange={handleTabChange}
                        orientation="vertical"
                        sx={tabListStyles}
                      >
                        {tabs.FindingsTab.map((tab, index) => (
                          <Tab
                            key={index}
                            className="navbar-text"
                            label={
                              <DomLink to={{ pathname: tab.link }}>
                                {tab.label}
                              </DomLink>
                            }
                            value={tab.value}
                            onMouseEnter={() => handleHover(tab.value)}
                          />
                        ))}
                      </TabList>
                    </Grid>
                    <Grid item xs={10}>
                      <TabPanel value="1">
                        <ShanksTab links={links} />
                      </TabPanel>
                      <TabPanel value="2">
                        <SettingsTab links={links} />
                      </TabPanel>
                      <TabPanel value="3">
                        <EarringsTab links={links} />
                      </TabPanel>
                      <TabPanel value="4">
                        <PendantsTab links={links} />
                      </TabPanel>
                      <TabPanel value="5">
                        <EarringCompTab links={links} />
                      </TabPanel>
                      <TabPanel value="6">
                        <ChainCompTab links={links} />
                      </TabPanel>
                      <TabPanel value="7">
                        <MoreProductsTab links={links} />
                      </TabPanel>
                    </Grid>
                  </Grid>
                </TabContext>
              </ul>
            </li>
            <li className="parent">
              <DomLink
                to={{
                  pathname: "/landing/Bands",
                }}
              >
                <Typography variant="navText">Bands</Typography>
              </DomLink>
              <ul>
                <Grid container>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">
                      Shop by Category
                    </Typography>
                    <Stack>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        All Bands
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            StonesRowCount: [
                              { label: "1 Row" },
                              { label: "2 Rows" },
                              { label: "3 Rows+" },
                            ],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Bands with Stones
                      </DomLink>
                      {/* <DomLink
                        to={{
                          pathname: "/products/Bands",
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Bands without Stones - Filter?
                      </DomLink> */}
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            BandType: [{ label: "Eternity" }],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Eternity Bands
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            BandType: [{ label: "Partway" }],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Partway Bands
                      </DomLink>
                      {/* <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            BandType: [{ label: "Eternity" }],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Men's Bands - Filter?
                      </DomLink> */}
                      {/* <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            BandType: [{ label: "Eternity" }],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Women's Bands - Filter?
                      </DomLink> */}
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            AdvancedOptions: [
                              { label: "Bridal Set" },
                              { label: "Can be made into Engagement Ring" },
                            ],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Wedding Bands
                      </DomLink>
                      {/* <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            BandType: [{ label: "Eternity" }],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Diamond Anniversary Bands - Filter?
                      </DomLink> */}
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            AdvancedOptions: [
                              { label: "Bridal Set" },
                              { label: "Can be made into Engagement Ring" },
                            ],
                            StonesRowCount: [
                              { label: "1 Row" },
                              { label: "2 Rows" },
                              { label: "3 Rows+" },
                            ],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Diamond Wedding Bands
                      </DomLink>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">
                      Shop by Setting Type
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            SettingType: [
                              { label: "Channel Set" },
                              { label: "Prong Set" },
                              { label: "Pave/Bead Set" },
                              { label: "Bar Set" },
                            ],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        All Settings
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            SettingType: [{ label: "Channel Set" }],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Channel
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            SettingType: [{ label: "Prong Set" }],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Prong
                      </DomLink>
                    </Stack>
                    <Typography className="navbar-text">
                      Shop by Metal Color
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            Material: [
                              { label: "10K White Gold" },
                              { label: "14K Pall White Gold" },
                              { label: "14K White Gold" },
                              { label: "18K White Gold" },
                              { label: "18K Pall White Gold" },
                              { label: "Sterling Silver" },
                              { label: "TruSilver" },
                            ],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        White
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            Material: [
                              { label: "10K Yellow Gold" },
                              { label: "14K Yellow Gold" },
                              { label: "18K Royal Yell Gold" },
                              { label: "18K Yellow Gold" },
                            ],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Yellow
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            Material: [
                              { label: "14K Red Gold" },
                              { label: "18K Pink Gold" },
                            ],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Pink
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                          state: {
                            Material: [
                              { label: "10K White Gold" },
                              { label: "10K Yellow Gold" },
                              { label: "14K Pall White Gold" },
                              { label: "14K Red Gold" },
                              { label: "14K White Gold" },
                              { label: "14K Yellow Gold" },
                              { label: "18K Pall White Gold" },
                              { label: "18K Pink Gold" },
                              { label: "18K Royal Yell Gold" },
                              { label: "18K White Gold" },
                              { label: "18K Yellow Gold" },
                            ],
                          },
                        }}
                        onClick={() => {
                          if (window.location.pathname === "/products/Bands") {
                            window.location.reload();
                          }
                        }}
                      >
                        Multi-Color
                      </DomLink>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">
                      Additional Info
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                        }}
                      >
                        Laser Engraving
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                        }}
                      >
                        Finish and Milgrain Options
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                        }}
                      >
                        Send us your Diamonds for Setting
                      </DomLink>
                    </Stack>
                    <Typography className="navbar-text">
                      Printed Materials
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                        }}
                      >
                        Bands Brochure
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/products/Bands",
                        }}
                      >
                        Diamond Bands Brochure
                      </DomLink>
                    </Stack>
                  </Grid>
                </Grid>
              </ul>
            </li>
            <li className="parent">
              <DomLink
                to={{
                  pathname: "/landing/Bridal-Jewelry",
                }}
              >
                <Typography variant="navText">Bridal</Typography>
              </DomLink>
              <ul>
                <TabContext value={hoverValue || value}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        borderRight: 1,
                        borderColor: "divider",
                      }}
                    >
                      <TabList
                        onChange={handleTabChange}
                        orientation="vertical"
                        sx={tabListStyles}
                      >
                        {tabs.RingTabs.map((tab, index) => (
                          <Tab
                            key={index}
                            className="navbar-text"
                            label={
                              <DomLink to={{ pathname: tab.link }}>
                                {tab.label}
                              </DomLink>
                            }
                            value={tab.value}
                            onMouseEnter={() => handleHover(tab.value)}
                          />
                        ))}
                      </TabList>
                    </Grid>
                    <Grid item xs={10}>
                      <TabPanel value="1">
                        <SolitairesTab links={links} />
                      </TabPanel>
                      <TabPanel value="2">
                        <EngagementRingTab links={links} />
                      </TabPanel>
                      <TabPanel value="3">
                        <BuildARingTab links={links} />
                      </TabPanel>
                    </Grid>
                  </Grid>
                </TabContext>
              </ul>
            </li>
            <li className="parent">
              <DomLink
                to={{
                  pathname: "/landing/Finished-Jewelry",
                }}
              >
                <Typography variant="navText">Finished Jewelry</Typography>
              </DomLink>
              <ul>
                <TabContext value={hoverValue || value}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        borderRight: 1,
                        borderColor: "divider",
                      }}
                    >
                      <TabList
                        onChange={handleTabChange}
                        orientation="vertical"
                        sx={tabListStyles}
                      >
                        {tabs.JewelryTabs.map((tab, index) => (
                          <Tab
                            key={index}
                            className="navbar-text"
                            label={
                              <DomLink to={{ pathname: tab.link }}>
                                {tab.label}
                              </DomLink>
                            }
                            value={tab.value}
                            onMouseEnter={() => handleHover(tab.value)}
                          />
                        ))}
                      </TabList>
                    </Grid>
                    <Grid item xs={10}>
                      <TabPanel value="1">
                        <SignetRingsTab links={links} />
                      </TabPanel>
                      <TabPanel value="2">
                        <FinishedDiamondJewelryTab links={links} />
                      </TabPanel>
                      <TabPanel value="3">
                        <BraceletsTab links={links} />
                      </TabPanel>
                      <TabPanel value="4">
                        <FashionTab links={links} />
                      </TabPanel>
                    </Grid>
                  </Grid>
                </TabContext>
              </ul>
            </li>
            <li className="parent">
              <DomLink
                to={{
                  pathname: "/landing/Diamonds",
                }}
              >
                <Typography variant="navText">
                  Diamonds and Gemstones
                </Typography>
              </DomLink>
              <ul>
                <Grid container>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">
                      Shop by Type
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      {links.DiamondLinks.type.map((link, index) => (
                        <DomLink key={index} to={{ pathname: link.path }}>
                          {link.label}
                        </DomLink>
                      ))}
                    </Stack>
                    <Typography className="navbar-text">
                      Diamond Services
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      <a
                        href="https://cms.hooverandstrong.com/uploads/LG_Screening_Services_Submission_Form_9c78239df0.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lab Grown Diamond Screening
                      </a>
                      <DomLink
                        to={{
                          pathname: "/stone-purchasing-service",
                        }}
                      >
                        Stone Purchasing
                      </DomLink>
                      <a
                        href="https://api2.hooverandstrong.com/pdfs/RefiningChainCustodyForm11-15-23.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Stone Removal
                      </a>
                      <a
                        href="https://api2.hooverandstrong.com/pdfs/RefiningChainCustodyForm11-15-23.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        CZ Frosting
                      </a>
                      <a
                        href="https://cms.hooverandstrong.com/uploads/Stone_Submission_Form_10_22_19_Final_f86e76ba8f.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Stone Setting
                      </a>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">Auctions</Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      {links.DiamondLinks.auctions.map((link, index) => (
                        <DomLink key={index} to={{ pathname: link.path }}>
                          {link.label}
                        </DomLink>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">
                      Additional Info
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      {links.AdditionalInfo.map((link, index) => (
                        <DomLink key={index} to={{ pathname: link.path }}>
                          {link.label}
                        </DomLink>
                      ))}
                    </Stack>
                    <Typography className="navbar-text">
                      Printed Materials
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      <a
                        href="https://cms.hooverandstrong.com/uploads/Diamonds_Brochure2_6_24_d151f58f4e.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Diamond Brochure
                      </a>
                    </Stack>
                  </Grid>
                </Grid>
              </ul>
            </li>
            <li className="parent">
              <DomLink
                to={{
                  pathname: "/accordion-landing/Contract-Casting",
                }}
              >
                <Typography variant="navText">Contract Casting</Typography>
              </DomLink>
              <ul>
                <Grid container>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Stack direction={"column"} className="navbar-stack">
                      <Typography className="navbar-text">
                        Casting Information
                      </Typography>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/What-You-Need-To-Know",
                        }}
                      >
                        What You Need to Know
                      </DomLink>
                      <a
                        href="https://cms.hooverandstrong.com/uploads/Casting_Time_Frames_Catalog_d33fdbc62a.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Casting Time Frames
                      </a>

                      <DomLink
                        to={{
                          pathname: "/casting-calculator",
                        }}
                      >
                        Online Calculator
                      </DomLink>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Stack direction={"column"} className="navbar-stack">
                      <Typography className="navbar-text">Forms</Typography>

                      <a
                        href="http://api2.hooverandstrong.com/pdfs/ContractCastingForm-10-22-19.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contract Casting Form
                      </a>

                      <a
                        href="http://api2.hooverandstrong.com/pdfs/FedExWaiver.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        FedEx Waiver
                      </a>
                      <a
                        href="https://api2.hooverandstrong.com/pdfs/Non-DisclosureAgreement6-7-18.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Non-Disclosure Agreement
                      </a>
                      <DomLink
                        to={{
                          pathname: "/stone-setting-submission-form",
                        }}
                      >
                        Stone Submission Form
                      </DomLink>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Stack direction={"column"} className="navbar-stack">
                      <Typography className="navbar-text">
                        Additional Info
                      </Typography>
                      <DomLink
                        to={{
                          pathname: "/diamonds",
                        }}
                      >
                        Diamond Sources
                      </DomLink>

                      <DomLink
                        to={{
                          pathname: "/melee-diamonds",
                        }}
                      >
                        Send us your Diamonds for Setting
                      </DomLink>

                      <DomLink
                        to={{
                          pathname: "/harmony-diamond-auctions",
                        }}
                      >
                        Diamond Info and Forms
                      </DomLink>

                      <DomLink
                        to={{
                          pathname: "/diamond-services",
                        }}
                      >
                        Responsibility Statements and Policies
                      </DomLink>

                      <DomLink
                        to={{
                          pathname: "/sub-landing/Our-Green-Refinery",
                        }}
                      >
                        Our Green Refinery
                      </DomLink>

                      <DomLink
                        to={{
                          pathname: "/sub-landing/Gemstones",
                        }}
                      >
                        Harmony Gemstones
                      </DomLink>
                    </Stack>
                    <Typography className="navbar-text">
                      Printed Materials
                    </Typography>
                    <Stack direction={"column"}>
                      <a
                        href="https://cms.hooverandstrong.com/uploads/Casting_Brochure1_29_24_b906631a5a.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Casting Brochure
                      </a>
                    </Stack>
                  </Grid>
                </Grid>
              </ul>
            </li>
            <li className="parent">
              <DomLink
                to={{
                  pathname: "/landing/Refining",
                }}
              >
                <Typography variant="navText">Refining</Typography>
              </DomLink>
              <ul>
                <Grid container>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Stack direction={"column"} className="navbar-stack">
                      <Typography className="navbar-text">
                        Refining Information
                      </Typography>
                      <DomLink
                        to={{
                          pathname: "/links/Refining-Payment-Terms",
                        }}
                      >
                        Refining Payment Terms
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/What-We-Refine",
                        }}
                      >
                        What We Refine
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/Finding-Your-Metal-Scrap",
                        }}
                      >
                        Finding Your Metal Scrap
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/Maximize-Your-Return",
                        }}
                      >
                        Maximize Your Return
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/Buying-Jewelry-Scrap",
                        }}
                      >
                        Buying Jewelry Scrap
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/Analytical-Services",
                        }}
                      >
                        Analytical Services
                      </DomLink>

                      <DomLink
                        to={{
                          pathname: "/refining-calculator",
                        }}
                      >
                        Refining Estimate Calculator
                      </DomLink>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">Forms</Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      <DomLink
                        to={{
                          pathname: "/chain-of-custody-form",
                        }}
                      >
                        Chain of Custody Form
                      </DomLink>

                      <DomLink
                        to={{
                          pathname: "/landing/Refining-For-Dentists",
                        }}
                      >
                        Refining for Dentists
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/landing/Refining-For-Private-Individuals",
                        }}
                      >
                        Refining for Private Individuals
                      </DomLink>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Stack direction={"column"} className="navbar-stack">
                      <Typography className="navbar-text">
                        Additional Info
                      </Typography>
                      <DomLink
                        to={{
                          pathname:
                            "/sub-landing/Responsibility-Statements-And-Policies",
                        }}
                      >
                        Responsibility Statements and Policies
                      </DomLink>

                      <DomLink
                        to={{
                          pathname: "/sub-landing/Our-Green-Refinery",
                        }}
                      >
                        Our Green Refinery
                      </DomLink>
                    </Stack>
                    <Typography className="navbar-text">
                      Printed Materials
                    </Typography>
                    <Stack direction={"column"}>
                      <a
                        href="https://cms.hooverandstrong.com/uploads/Refining_Brochure11_7_24_dec48bd198.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Refining Brochure
                      </a>
                    </Stack>
                  </Grid>
                </Grid>
              </ul>
            </li>
            <li className="parent">
              <DomLink
                to={{
                  pathname: "/landing/Harmony",
                }}
              >
                <Typography variant="navText">Harmony</Typography>
              </DomLink>
              <ul>
                <Grid container>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Stack direction={"column"} className="navbar-stack">
                      <Typography className="navbar-text">
                        Harmony Information
                      </Typography>
                      <DomLink
                        to={{
                          pathname:
                            "/sub-landing/Harmony-Recycled-Precious-Metals",
                        }}
                      >
                        Harmony Recycled Precious Metals
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/Artisanal-Metals",
                        }}
                      >
                        Artisanal Metals
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/Our-Green-Refinery",
                        }}
                      >
                        Our Green Refinery
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/harmony-diamond-auctions",
                        }}
                        onClick={() => props.setShowMobileMenu(false)}
                      >
                        Harmony Diamond Auctions
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/Lab-Grown-Diamonds",
                        }}
                        onClick={() => props.setShowMobileMenu(false)}
                      >
                        Lab Grown Diamonds
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/landing/Diamonds",
                        }}
                        onClick={() => props.setShowMobileMenu(false)}
                      >
                        Diamonds and Melee
                      </DomLink>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} sm={4} lg={4} xl={2}>
                    <Typography className="navbar-text">
                      Additional Info
                    </Typography>
                    <Stack direction={"column"} className="navbar-stack">
                      <DomLink
                        to={{
                          pathname:
                            "/sub-landing/Responsibility-Statements-And-Policies",
                        }}
                      >
                        Responsibility Statements and Policies
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/Harmony-Jewelers",
                        }}
                      >
                        Find a Harmony Jeweler
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/sub-landing/Charities-And-Sponsorships",
                        }}
                      >
                        Charities and Sponsorship
                      </DomLink>
                      <DomLink
                        to={{
                          pathname: "/links/Articles-And-Press-Releases",
                        }}
                      >
                        In the News
                      </DomLink>
                    </Stack>
                  </Grid>
                </Grid>
              </ul>
            </li>
          </Stack>
        </AppBar>
      </Default>
    </>
  );
}
