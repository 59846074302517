import { api, storage } from "../../utils";
import gaFunctions from "../../utils/GAComponents/GAFunctions";

function deleteLineItem(lineItem, cartDispatch) {
  const toDelete = {
    cart_id: storage.getStorageItem("cartId"),
    id: lineItem.id,
  };
  api.post("cart/remove", toDelete).then((res) => {
    cartDispatch({
      type: "updateCart",
      payload: {
        cart: res.data.items,
        cartCount: res.data.count,
      },
    });
    gaFunctions.gaRemoveFromCart(lineItem);
  });
}

function subtotal(cart) {
  return cart.reduce((acc, item) => {
    if (item.invoices.length > 0) {
      return acc + Number(item.invoices[0].total);
    }
    return acc;
  }, 0);
}

export { deleteLineItem, subtotal };
