const links = {
  MillProductLinks: [
    { path: "/product/GR", label: "Casting Grain" },
    { path: "/product/ALL", label: "Casting Alloy" },
    { path: "/product/FS", label: "Flat Sheet" },
    { path: "/products/Wire", label: "Wire" },
    { path: "/products/Tubing", label: "Tubing" },
    { path: "/group/SOLDER", label: "Solder" },
    { path: "/product/COINS BULLION", label: "Coins & Bullion" },
  ],
  AlloyInformation: [
    {
      path: "/links/Material-Specification-Sheets",
      label: "Material Spec Sheets",
    },
    { path: "/links/Safety-Data-Sheets", label: "Safety Data Sheets" },
    { path: "/links/Hoover-And-Strong-Metals-Chart", label: "Metals Chart" },
  ],
  ResponsibilityLinks: [
    {
      path: "/sub-landing/Responsibility-Statements-And-Policies",
      label: "Responsibility Statements and Policies",
    },
    {
      path: "/sub-landing/Harmony-Recycled-Precious-Metals",
      label: "Harmony Recycled Precious Metals",
    },
    {
      path: "/sub-landing/Artisanal-Metals",
      label: "Artisanal Metals",
    },
    {
      path: "/sub-landing/Our-Green-Refinery",
      label: "Our Green Refinery",
    },
  ],
  MillBrochureLink: [
    {
      path: "https://cms.hooverandstrong.com/uploads/Mill_Products_Brochure_5_9_24_3482ef3346.pdf",
      label: "Mill Brochure",
    },
  ],
  ShanksLinks: {
    MetalColor: [
      {
        label: "White",
        materials: [
          { label: "10K White Gold" },
          { label: "14K Pall White Gold" },
          { label: "14K White Gold" },
          { label: "18K White Gold" },
          { label: "18K Pall White Gold" },
          { label: "Sterling Silver" },
        ],
      },
      {
        label: "Yellow",
        materials: [
          { label: "10K Yellow Gold" },
          { label: "14K Yellow Gold" },
          { label: "18K Royal Yell Gold" },
        ],
      },
      {
        label: "Pink",
        materials: [{ label: "14K Red Gold" }, { label: "18K Pink Gold" }],
      },
    ],
    ShankType: [
      { label: "4 Prong", type: [{ label: "4 Prong" }] },
      { label: "6 Prong", type: [{ label: "6 Prong" }] },
    ],
    ShankStyle: [
      { label: "Half Round", style: [{ label: "Half Round" }] },

      { label: "Flat Shoulder", style: [{ label: "Flat Shoulder" }] },

      { label: "High Shoulder", style: [{ label: "High Shoulder" }] },

      { label: "Tapered", style: [{ label: "Tapered" }] },
      { label: "Cathedral", style: [{ label: "Cathedral" }] },
      { label: "Airline", style: [{ label: "Airline" }] },
      { label: "Knife Edge", style: [{ label: "Knife Edge" }] },
      { label: "Adjusto", style: [{ label: "Adjusto" }] },
    ],
    ShankAdvanceOptions: [
      {
        label: "Bridal Set",
        options: [{ label: "Bridal Set" }],
      },
      {
        label: "EzFit",
        options: [{ label: "EzFit" }],
      },
      {
        label: "Die Struck",
        options: [{ label: "Die Struck" }],
      },
      {
        label: "Cast",
        options: [{ label: "Cast" }],
      },
      {
        label: "Standard Fit",
        options: [{ label: "Standard Fit" }],
      },
    ],
  },
  SettingsLinks: {
    MetalColor: [
      {
        label: "White",
        materials: [
          { label: "10K White Gold" },
          { label: "14K Pall White Gold" },
          { label: "14K White Gold" },
          { label: "18K White Gold" },
          { label: "18K Pall White Gold" },
          { label: "Sterling Silver" },
        ],
      },
      {
        label: "Yellow",
        materials: [
          { label: "10K Yellow Gold" },
          { label: "14K Yellow Gold" },
          { label: "18K Royal Yell Gold" },
          { label: "22K Yellow Gold" },
        ],
      },
      {
        label: "Pink",
        materials: [{ label: "14K Red Gold" }, { label: "18K Pink Gold" }],
      },
    ],
    SettingType: [
      { label: "Peg", type: [{ label: "Peg" }] },
      { label: "Solitaire", type: [{ label: "Solitaire" }] },
      { label: "Cup", type: [{ label: "Cup" }] },
      { label: "Basket", type: [{ label: "Basket" }] },
      { label: "Bezel", type: [{ label: "Bezel" }] },
    ],
    SettingShape: [
      { label: "Round", shape: [{ label: "Round" }] },
      { label: "Princess", shape: [{ label: "Princess" }] },
      { label: "Emerald", shape: [{ label: "Emerald" }] },
      { label: "Marquise", shape: [{ label: "Marquise" }] },
      { label: "Pear", shape: [{ label: "Pear" }] },
      { label: "Oval", shape: [{ label: "Oval" }] },
      { label: "Heart", shape: [{ label: "Heart" }] },
      { label: "Cushion", shape: [{ label: "Cushion" }] },
      { label: "Asscher", shape: [{ label: "Asscher" }] },
      { label: "Trillion", shape: [{ label: "Trillion" }] },
    ],
    SettingProngs: [
      { label: "3 Prong", type: [{ label: "3 Prong" }] },
      { label: "4 Prong", type: [{ label: "4 Prong" }] },
      { label: "5 Prong", type: [{ label: "5 Prong" }] },
      { label: "6 Prong", type: [{ label: "6 Pronge" }] },
      { label: "4 Split Prong", type: [{ label: "4 Split Prong" }] },
      { label: "4 Double Prong", type: [{ label: "4 Double Prong" }] },
      { label: "V-End", type: [{ label: "V-End" }] },
    ],
    SettingAdvanceOptions: [
      {
        label: "Die Struck",
        type: [{ label: "Die Struck" }],
      },
      {
        label: "Cast",
        type: [{ label: "Cast" }],
      },
    ],
  },
  EarringsLinks: {
    MetalColor: [
      {
        label: "White",
        materials: [
          { label: "10K White Gold" },
          { label: "14K White Gold" },
          { label: "18K White Gold" },
          { label: "Sterling Silver" },
        ],
      },
      {
        label: "Yellow",
        materials: [
          { label: "10K Yellow Gold" },
          { label: "14K Yellow Gold" },
          { label: "18K Royal Yell Gold" },
        ],
      },
      {
        label: "Pink",
        materials: [{ label: "14K Red Gold" }],
      },
    ],
    EarringsPost: [
      { label: "Single Notch", type: [{ label: "Single Notch" }] },
      { label: "Double Notch", type: [{ label: "Double Notch" }] },
    ],
    EarringsShape: [
      { label: "Round", shape: [{ label: "Round" }] },
      { label: "Princess", shape: [{ label: "Princess" }] },
    ],
    EarringsProngs: [
      { label: "3 Prong", type: [{ label: "3 Prong" }] },
      { label: "4 Prong", type: [{ label: "4 Prong" }] },
    ],
    EarringsAdvanceOptions: [
      {
        label: "Halo",
        type: [{ label: "Halo" }],
      },
      {
        label: "Cast",
        type: [{ label: "Cast" }],
      },
      {
        label: "Martini",
        type: [{ label: "Martini" }],
      },
    ],
  },
  PendantsLinks: {
    MetalColor: [
      {
        label: "White",
        materials: [
          { label: "10K White Gold" },
          { label: "14K White Gold" },
          { label: "18K White Gold" },
        ],
      },
      {
        label: "Yellow",
        materials: [
          { label: "10K Yellow Gold" },
          { label: "14K Yellow Gold" },
          { label: "18K Royal Yell Gold" },
        ],
      },
      {
        label: "Pink",
        materials: [{ label: "14K Red Gold" }],
      },
    ],
    PendantsStyle: [
      { label: "Single Bail", type: [{ label: "Single Bail" }] },
      { label: "Double Bail", type: [{ label: "Double Bail" }] },
    ],
    PendantsShape: [
      { label: "Round", shape: [{ label: "Round" }] },
      { label: "Princess", shape: [{ label: "Princess" }] },
      { label: "Emerald", shape: [{ label: "Emerald" }] },
      { label: "Marquise", shape: [{ label: "Marquise" }] },
      { label: "Pear", shape: [{ label: "Pear" }] },
      { label: "Oval", shape: [{ label: "Oval" }] },
      { label: "Heart", shape: [{ label: "Heart" }] },
    ],
    PendantsProngs: [
      { label: "4 Prong", type: [{ label: "4 Prong" }] },
      { label: "V-End", type: [{ label: "4 Prong" }] },
    ],
    PendantsAdvanceOptions: [
      { label: "Die Struck", type: [{ label: "Die Struck" }] },
      { label: "Cast", type: [{ label: "Cast" }] },
      { label: "Halo", type: [{ label: "Halo" }] },
      { label: "Basket", type: [{ label: "Basket" }] },
      { label: "Cup", type: [{ label: "Cup" }] },
      { label: "Tension", type: [{ label: "Tension" }] },
      {
        label: "Earring & Pendant Suites",
        type: [{ label: "Earring & Pendant Suites" }],
      },
    ],
  },
  EarringComponentLink: {
    MetalColor: [
      {
        label: "White",
        materials: [
          { label: "3K White Gold" },
          { label: "10K White Gold" },
          { label: "14K White Gold" },
          { label: "18K White Gold" },
          { label: "Sterling Silver" },
        ],
      },
      {
        label: "Yellow",
        materials: [
          { label: "10K Yellow Gold" },
          { label: "14K Yellow Gold" },
          { label: "18K Royal Yell Gold" },
        ],
      },
      {
        label: "Pink",
        materials: [{ label: "14K Red Gold" }],
      },
    ],
    EarringCompType: [
      { label: "Post", type: [{ label: "Post" }] },
      { label: "Back", type: [{ label: "Back" }] },
      { label: "Bail", type: [{ label: "Bail" }] },
      { label: "Stems", type: [{ label: "Stems" }] },
      { label: "Cuff Link", type: [{ label: "Cuff Link" }] },
    ],
    EarringCompAdvanceOptions: [
      { label: "Die Struck", type: [{ label: "Die Struck" }] },
      { label: "Single Notch", type: [{ label: "Single Notch" }] },
      { label: "Pearl", type: [{ label: "Pearl" }] },
      { label: "Threaded", type: [{ label: "Threaded" }] },
      { label: "Friction", type: [{ label: "Friction" }] },
      { label: "LuxLock", type: [{ label: "LuxLock" }] },
    ],
  },
  ChainComponentLink: {
    MetalColor: [
      {
        label: "White",
        materials: [
          { label: "14K White Gold" },
          { label: "18K White Gold" },
          { label: "Sterling Silver" },
        ],
      },
      {
        label: "Yellow",
        materials: [{ label: "14K Yellow Gold" }, { label: "18K Yellow Gold" }],
      },
      {
        label: "Pink",
        materials: [{ label: "14K Red Gold" }],
      },
    ],
    ChainCompType: [
      { label: "Finished CHain", type: [{ label: "Finished CHain" }] },
      { label: "Bulk Chain", type: [{ label: "Bulk Chain" }] },
      { label: "Clasps", type: [{ label: "Clasps" }] },
      { label: "Jump Rings", type: [{ label: "Jump Rings" }] },
    ],
  },
  SolitaireLinks: {
    MetalColor: [
      {
        label: "White",
        materials: [{ label: "14K White Gold" }],
      },
      {
        label: "Yellow",
        materials: [{ label: "14K Yellow Gold" }],
      },
      {
        label: "Pink",
        materials: [{ label: "14K Red Gold" }],
      },
    ],
    SolitairesFit: [{ label: "Standard", type: [{ label: "Standard" }] }],
    SolitairesStyle: [
      { label: "Half Round", type: [{ label: "Half Round" }] },
      { label: "Cathedral", type: [{ label: "Cathedral" }] },
      { label: "Knife Edge", type: [{ label: "Knife Edge" }] },
      { label: "Trellis Style", type: [{ label: "Trellis Style" }] },
      { label: "With Sidestones", type: [{ label: "With Sidestones" }] },
    ],
    SolitairesShape: [
      { label: "Round", shape: [{ label: "Round" }] },
      { label: "Princess", shape: [{ label: "Princess" }] },
      { label: "Emerald", shape: [{ label: "Emerald" }] },
      { label: "Marquise", shape: [{ label: "Marquise" }] },
      { label: "Pear", shape: [{ label: "Pear" }] },
    ],
    SolitairesProngs: [{ label: "4 Prong", type: [{ label: "4 Prong" }] }],
    SolitairesAdvanceOptions: [
      {
        label: "EzFit",
        type: [{ label: "EzFit" }],
      },
      {
        label: "Die Struck",
        type: [{ label: "Die Struck" }],
      },
      {
        label: "Standard Fit",
        type: [{ label: "Standard Fit" }],
      },
    ],
  },
  EngagementLinks: {
    MetalColor: [
      {
        label: "White",
        materials: [{ label: "14K White Gold" }],
      },
      {
        label: "Yellow",
        materials: [{ label: "14K Yellow Gold" }, { label: "18K Yellow Gold" }],
      },
    ],
    EngagementStyle: [
      { label: "Half Round", type: [{ label: "Half Round" }] },
      { label: "Cathedral", type: [{ label: "Cathedral" }] },
      { label: "Partway", type: [{ label: "Partway" }] },
      { label: "Airline", type: [{ label: "Airline" }] },
      { label: "Knife Edge", type: [{ label: "Knife Edge" }] },
      { label: "Milgrain", type: [{ label: "Milgrain" }] },
      { label: "Halo", type: [{ label: "Halo" }] },
      { label: "Split Shoulder", type: [{ label: "Split Shoulder" }] },
      { label: "Trellis Style", type: [{ label: "Trellis Style" }] },
      { label: "Bypass", type: [{ label: "Bypass" }] },
      { label: "Two Stone", type: [{ label: "Two Stone" }] },
      { label: "Patterned", type: [{ label: "Patterned" }] },
      { label: "Three Stone", type: [{ label: "Three Stone" }] },
    ],
    EngagementShape: [
      { label: "Round", shape: [{ label: "Round" }] },
      { label: "Princess", shape: [{ label: "Princess" }] },
    ],
    EngagementAdvanceOptions: [
      {
        label: "Bridal Set",
        type: [{ label: "Bridal Set" }],
      },
      {
        label: "Die Struck",
        type: [{ label: "Die Struck" }],
      },
      {
        label: "Cast",
        type: [{ label: "Cast" }],
      },
      {
        label: "Standard Fit",
        type: [{ label: "Standard Fit" }],
      },
    ],
  },
  SignetRingLinks: {
    MetalColor: [
      {
        label: "White",
        materials: [{ label: "14K White Gold" }, { label: "10K White Gold" }],
      },
      {
        label: "Yellow",
        materials: [{ label: "14K Yellow Gold" }],
      },
      {
        label: "Pink",
        materials: [{ label: "14K Red Gold" }],
      },
    ],
    SignetType: [{ label: "Patterned", type: [{ label: "Patterned" }] }],
    SignetStyle: [
      { label: "Half Round", type: [{ label: "Half Round" }] },
      { label: "Cathedral", type: [{ label: "Cathedral" }] },
      { label: "Knife Edge", type: [{ label: "Knife Edge" }] },
      { label: "Trellis Style", type: [{ label: "Trellis Style" }] },
      { label: "With Sidestones", type: [{ label: "With Sidestones" }] },
    ],
    SignetShape: [
      { label: "Round", shape: [{ label: "Round" }] },
      { label: "Marquise", shape: [{ label: "Marquise" }] },
      { label: "Oval", shape: [{ label: "Oval" }] },
      { label: "Cushion", shape: [{ label: "Cushion" }] },
      { label: "Square", shape: [{ label: "Square" }] },
      { label: "Rectangle", shape: [{ label: "Rectangle" }] },
    ],
  },
  BraceletsLinks: {
    MetalColor: [
      {
        label: "White",
        materials: [{ label: "14K White Gold" }],
      },
      {
        label: "Yellow",
        materials: [{ label: "14K Yellow Gold" }],
      },
    ],
  },
  FashionLinks: {
    MetalColor: [
      {
        label: "White",
        materials: [{ label: "14K White Gold" }],
      },
      {
        label: "Yellow",
        materials: [{ label: "14K Yellow Gold" }],
      },
    ],
  },
  MoreProductsLink: [
    { label: "Circles", value: "7", links: "/products/Circles" },
    { label: "Alphabets", value: "8", links: "/products/Alphabets" },
    { label: "Washers", value: "9", links: "/product/WAS" },
    { label: "Charms", value: "10", links: "/products/Charms" },
    { label: "Ring Blanks", value: "11", links: "/product/WRB" },
  ],
  FindingsInfo: [
    { path: "/landing/Findings", label: "Send us your Diamonds for Setting" },
    { path: "/links/Laser-Engraving", label: "Laser Engraving" },
  ],
  FindingsMaterial: [
    {
      path: "https://cms.hooverandstrong.com/uploads/Bridal_Brochure_2_27_24_6f5cf157b7.pdf",
      label: "Bridal Brochure",
    },
  ],
  FinishedJewelryInfo: [
    {
      path: "/links/Machine-Set-Inside-Out-Earrings",
      label: "Machine Set Inside Out Earrings",
    },
    {
      path: "/sub-landing/Responsibility-Statements-And-Policies",
      label: "Responsibility Statements and Policies",
    },
    {
      path: "/sub-landing/Harmony-Recycled-Precious-Metals",
      label: "Harmony Recycled Precious Metals",
    },
    { path: "/sub-landing/Our-Green-Refinery", label: "Our Green Refinery" },
    { path: "/sub-landing/Diamond-Services", label: "Diamond Services" },
  ],
  FinishedJewelryOutlet: [
    {
      path: "https://outlet.hooverandstrong.com",
      label: "Outlet & Unique Finds",
    },
  ],
  FinishedDiamondJewelryLinks: [
    {
      path: "https://outlet.hooverandstrong.com/collections/finished-lab-grown-diamond-jewelry",
      label: "Diamond Ear Studs",
    },
    {
      path: "https://outlet.hooverandstrong.com/collections/finished-lab-grown-diamond-jewelry",
      label: "Diamond Pendants",
    },
    {
      path: "https://outlet.hooverandstrong.com/collections/finished-lab-grown-diamond-jewelry",
      label: "Diamond Pendants",
    },
    {
      path: "https://outlet.hooverandstrong.com/collections/finished-lab-grown-diamond-jewelry",
      label: "Diamond Bands",
    },
  ],
  DiamondLinks: {
    type: [
      { path: "/diamonds", label: "Certified Diamond Search" },
      { path: "/melee-diamonds", label: "Melee Diamond Search" },
      { path: "/diamond-services", label: "Diamond Services" },
      { path: "/sub-landing/Gemstones", label: "Gemstones" },
    ],
    auctions: [
      {
        path: "/harmony-diamond-auctions",
        label: "Harmony Diamond Auctions",
      },
    ],
    brochures: [{ path: "" }],
  },
  AdditionalInfo: [
    { path: "/sub-landing/Diamond-Sources", label: "Diamond Sources" },
    { path: "/melee-diamonds", label: "Send us your Diamonds for Setting" },
    { path: "/links/Diamond-Info-And-Forms", label: "Diamond Info and Forms" },
    {
      path: "/sub-landing/Responsibility-Statements-And-Policies",
      label: "Responsibility Statements and Policies",
    },
    {
      path: "/sub-landing/Our-Green-Refinery",
      label: "Our Green Refinery",
    },
    { path: "/sub-landing/Gemstones", label: "Gemstones" },
  ],
};

export default links;
