// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Button,
  Container,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// Local modules
import api from "../../../utils/api";
import { constants } from "../../../utils";
import LandingFooter from "./LandingFooter";

export default function StaticSubLanding(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState();
  const [twocolumns, setTwoColumns] = useState();
  const [threecards, setThreeCards] = useState();
  const [bottomgreen, setBottomGreen] = useState();
  const [icons, setIcons] = useState();

  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  const pageMapping = {
    "Harmony-Recycled-Precious-Metals":
      constants.HARMONY_RECYCLED_PRECIOUS_METALS_ID,
    "Lab-Grown-Diamonds": constants.LAB_GROWN_DIAMONDS_ID,
    "Harmony-Recycled-Diamonds": constants.HARMONY_RECYCLED_DIAMONDS_ID,
    "Artisanal-Metals": constants.ARTISANAL_METALS,
    "Our-Green-Refinery": constants.OUR_GREEN_REFINERY_ID,
    "Harmony-Diamond-Auctions": constants.HARMONY_DIAMOND_AUCTIONS,
    "The-Hoover-And-Strong-Experts": constants.THE_HOOVER_AND_STRONG_EXPERTS,
    "Responsibility-Statements-And-Policies":
      constants.RESPONSIBILITY_STATEMENTS_AND_POLICIES,
    "Our-History": constants.OUR_HISTORY,
    "Volume-Discount-Programs": constants.VOLUME_DISCOUNT_PROGRAMS,
    "Shipping-Options": constants.SHIPPING_OPTIONS,
    "Payment-Information": constants.PAYMENT_INFORMATION,
    "Diamond-Services": constants.DIAMOND_SERVICES,
    "Diamond-Sources": constants.DIAMOND_SOURCES,
    "Finishing-And-Milgrain-Options": constants.FINISHING_AND_MILGRAIN_OPTIONS,
    "Customer-Service-Team": constants.CUSTOMER_SERVICE_TEAM,
    "What-We-Refine": constants.WHAT_WE_REFINE,
    "Stone-Removal": constants.STONE_REMOVAL,
    "Finding-Your-Metal-Scrap": constants.FINDING_YOUR_METAL_SCRAP,
    "Maximize-Your-Return": constants.MAXIMIZE_YOUR_RETURN,
    "Buying-Jewelry-Scrap": constants.BUYING_JEWELRY_SCRAP,
    "Free-Sweeps-Box": constants.FREE_SWEEPS_BOX,
    "Preparing-Your-Shipment": constants.PREPARING_YOUR_SHIPMENT,
    "Analytical-Services": constants.ANALYTICAL_SERVICES,
    "What-You-Need-To-Know": constants.WHAT_YOU_NEED_TO_KNOW,
    "Refining-Settlement-Options": constants.REFINING_SETTLEMENT_OPTIONS,
    "Identifying-Your-Scrap": constants.IDENTIFYING_YOUR_SCRAP,
    "Payment-Options": constants.PAYMENT_OPTIONS,
    "Harmony-Jewelers": constants.HARMONY_JEWELERS,
    "Diamond-Education": constants.DIAMOND_EDUCATION,
    "Charities-And-Sponsorships": constants.CHARITIES_AND_SPONSERSHIPS,
    Gemstones: constants.GEMSTONES,
    Sapphires: constants.SAPPHIRES,
    "African-Treasures": constants.AFRICAN_TREASURES,
    Tourmalines: constants.TOURMALINES,
    "Recycled Gemstones": constants.RECYCLED_GEMSTONES,
  };

  const page = pageMapping[props.match.params.PageType] || 1;
  useEffect(() => {
    window.scrollTo(0, 0);
    const query = qs.stringify({
      populate: {
        title: {
          populate: "*",
        },
        banner: {
          populate: "*",
        },
        twocolumns: {
          populate: "*",
        },
        threecards: {
          populate: "*",
        },
        bottomgreen: {
          populate: "*",
        },
        icons: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/sub-landing-pages/${page}?${query}`).then((response) => {
      setBanner(
        response.data.data.attributes.banner.data
          ? response.data.data.attributes.banner.data.attributes
          : null
      );
      setTwoColumns(response.data.data.attributes.twocolumns);
      setThreeCards(response.data.data.attributes.threecards);
      setBottomGreen(response.data.data.attributes.bottomgreen);
      setIcons(response.data.data.attributes.icons);
      setIsLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.PageType]);

  return (
    <>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          <Stack spacing={3} alignItems="center">
            {banner && (
              <img
                src={`${frontImageUrl}${banner.url}`}
                width="100%"
                alt="banner"
              />
            )}
            {twocolumns &&
              twocolumns.map((twocolumn) => (
                <>
                  <Stack spacing={3}>
                    <Typography variant="articleTitle" align="center">
                      {twocolumn.title}
                    </Typography>
                    <Grid container>
                      <Grid item xs={6} sx={{ paddingRight: "2.5rem" }}>
                        <Stack spacing={1}>
                          {twocolumn.leftimageplacement === "top" && (
                            <img
                              src={`${frontImageUrl}${twocolumn.leftimage.data.attributes.url}`}
                              width="100%"
                              alt="banner"
                            />
                          )}
                          <Typography variant="staticText">
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                              {twocolumn.leftcolumn}
                            </ReactMarkdown>
                          </Typography>
                          {twocolumn.leftbutton && (
                            <DomLink
                              to={{ pathname: `${twocolumn.leftbutton.to}` }}
                              className="center"
                            >
                              <Button variant="contained">
                                {twocolumn.leftbutton.text}
                              </Button>
                            </DomLink>
                          )}

                          {twocolumn.leftimageplacement === "bottom" && (
                            <img
                              src={`${frontImageUrl}${twocolumn.leftimage.data.attributes.url}`}
                              width="100%"
                              alt="banner"
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={6} sx={{ paddingLeft: "2.5rem" }}>
                        {twocolumn.rightimageplacement === "top" && (
                          <img
                            src={`${frontImageUrl}${twocolumn.rightimage.data.attributes.url}`}
                            width="100%"
                            alt="banner"
                          />
                        )}
                        <Typography variant="staticText">
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {twocolumn.rightcolumn}
                          </ReactMarkdown>
                        </Typography>
                        {twocolumn.rightbutton && (
                          <DomLink
                            to={{ pathname: `${twocolumn.rightbutton.to}` }}
                            className="center"
                          >
                            <Button variant="contained">
                              {twocolumn.rightbutton.text}
                            </Button>
                          </DomLink>
                        )}

                        {twocolumn.rightimageplacement === "bottom" && (
                          <img
                            src={`${frontImageUrl}${twocolumn.rightimage.data.attributes.url}`}
                            width="100%"
                            alt="banner"
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Divider flexItem />
                  </Stack>
                </>
              ))}

            {threecards && (
              <>
                {threecards.title && (
                  <Typography variant="articleTitle">
                    {threecards.title}
                  </Typography>
                )}
                <Stack
                  direction="row"
                  key={threecards.id}
                  spacing={4}
                  align="center"
                  justifyContent="center"
                >
                  <Card variant="outlined" sx={{ width: "33%" }}>
                    <img
                      src={`${frontImageUrl}${threecards.leftimage.data.attributes.url}`}
                      alt="left"
                      style={{ paddingTop: "2rem" }}
                    />
                    <CardContent>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {threecards.leftcard}
                        </ReactMarkdown>
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card variant="outlined" sx={{ width: "33%" }}>
                    <img
                      src={`${frontImageUrl}${threecards.middleimage.data.attributes.url}`}
                      alt="middle"
                      style={{ paddingTop: "2rem" }}
                    />
                    <CardContent>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {threecards.middlecard}
                        </ReactMarkdown>
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card variant="outlined" sx={{ width: "33%" }}>
                    <img
                      src={`${frontImageUrl}${threecards.rightimage.data.attributes.url}`}
                      alt="right"
                      style={{ paddingTop: "2rem" }}
                    />
                    <CardContent>
                      <Typography variant="staticText">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {threecards.rightcard}
                        </ReactMarkdown>
                      </Typography>
                    </CardContent>
                  </Card>
                </Stack>
              </>
            )}
            <Typography variant="greenRectangle" align="center">
              {bottomgreen.text}
            </Typography>
            <LandingFooter icons={icons} />
          </Stack>
        </Container>
      )}
    </>
  );
}
