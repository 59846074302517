// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useReducer, useState, useContext } from "react";
import { Link as DomLink } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Grid,
  Divider,
  Container,
  Stack,
  Typography,
  Backdrop,
  MenuItem,
  CircularProgress,
  Select,
} from "@mui/material";

// Local modules
import {
  AppContext,
  ProductsContext,
} from "../../../utils/stateHandlers/contexts";
import { productReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import { storage } from "../../../utils";
import api from "../../../utils/api";
import { functions, constants } from "../../../utils";
import CharmsImage from "../../../assets/images/CHM_S_XLG.png";
import Price from "../components/Price";
import DetailTable from "../components/DetailTable";
import TechnicalArticles from "../components/TechnicalArticles";
import CustomerTestimonials from "../components/CustomerTestimonials";
import SpecialInstructions from "../components/SpecialInstructions";
import TreatmentField from "../components/TreatmentField";
import Material from "../components/productFields/Material";
import Quantity from "../components/productFields/Quantity";
import Thickness from "../components/productFields/Thickness";

import filterAds from "../../../utils/helperFunction";
import StrapiChart from "./StrapiChart";
import MetalColumns from "./MetalColumns";
import RelatedItems from "./RelatedItems";
import CompatibleProduct from "./CompatibleProduct";
import RecentlyView from "./RecentlyView";
import updateFields from "./util/helperFunction";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function Charms(props) {
  const { authToken } = useContext(AppContext);
  const [productState, productDispatch] = useReducer(
    productReducer,
    states.initialProductState
  );
  const {
    pageLoaded,
    productInfo,
    productDetails,
    icons,
    productFields,
    productTreatments,
    compatibleProducts,
    relatedProducts,
    metalColumns,
    strapiChart,
    TopText,
    BottomText,
    ChartSizeSmall,
    charmDimension,
    advertisement,
  } = productState;
  const [charmShape, setCharmShape] = useState(null);
  const [charmShapeOptions, setCharmShapeOptions] = useState();
  const [filter, setFilter] = useState({});
  const [charmStyle, setCharmStyle] = useState(null);
  const [charmStyles, setCharmStyles] = useState();
  const [styleLoaded, setStyleLoaded] = useState(false);

  const productGroup = props.ProductGroup;
  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  function createProductDefaults(product) {
    setStyleLoaded(false);
    var productDefaults = {};
    productDefaults = {
      ...productDefaults,
      product: product.product,
      description: product.description,
      treatments: "",
      dimension: `${product.dimension}x20GA`,
    };

    const dispatchFields = [
      "product",
      "description",
      "treatments",
      "dimension",
    ];

    productDispatch({
      type: "setStyle",
      payload: product,
    });

    dispatchFields.forEach((field) => {
      productDispatch({
        type: "setProductDefaults",
        field: field,
        payload: productDefaults[field],
      });
    });

    product.fields.forEach(function (field) {
      if (field.name !== "dimension") {
        if (field.template === "field_with_units") {
          productDispatch({
            type: "setProductDefaults",
            field: field.name,
            payload: field.default,
          });
          productDispatch({
            type: "setProductDefaults",
            field: `${field.name}_unit`,
            payload: field.default_unit,
          });
          productDefaults = {
            ...productDefaults,
            [field.name]: field.default,
            [`${field.name}_unit`]: field.default_unit,
          };
        } else {
          productDispatch({
            type: "setProductDefaults",
            field: field.name,
            payload: field.default,
          });
          productDefaults = {
            ...productDefaults,
            [field.name]: field.default,
          };
        }
      }
    });
    if (authToken) {
      getInitialPrice(productDefaults);
    }
  }

  function makeInitialQueryString(productDefaults) {
    const query = qs.stringify({
      product: productDefaults.product,
      dimension: productDefaults.dimension,
      quantity: productDefaults.quantity,
      quantity_unit: productDefaults.quantity_unit,
      material: productDefaults.material,
      carat_size: productDefaults.carat_size,
      message: productDefaults.message,
      finger_size: productDefaults.finger_size,
      treatments: "",
    });
    return query;
  }

  function getInitialPrice(productDefaults) {
    const query = makeInitialQueryString(productDefaults);
    api.fetch(`quickorder/price?${query}`).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = [];
        res.response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        productDispatch({
          type: "setMessage",
          payload: {
            message: Object.values(res.response.data.errors),
            type: "error",
          },
        });
      } else {
        productDispatch({ type: "setPrice", payload: res.data });
        productDispatch({ type: "setPriceLoaded", payload: true });
        setStyleLoaded(true);
      }
    });
  }

  function setTreatmentsString() {
    const treatmentsList = Array.isArray(productInfo.treatments)
      ? productInfo.treatments.map((treatment) => treatment.value)
      : [];

    return treatmentsList.join(" ");
  }

  function makeQueryString() {
    const query = qs.stringify({
      product: productInfo.product,
      dimension: `${charmDimension}x${productInfo.thickness}${productInfo.thickness_unit}`,
      quantity: productInfo.quantity,
      quantity_unit: productInfo.quantity_unit,
      material: productInfo.material,
      carat_size: productInfo.carat_size,
      message: productInfo.message,
      finger_size: productInfo.finger_size,
      treatments: setTreatmentsString(),
    });
    return query;
  }

  function getUpdatedPrice(newQueryString) {
    api.fetch(`quickorder/price?${newQueryString}`).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = [];
        res.response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        productDispatch({
          type: "setMessage",
          payload: {
            message: Object.values(res.response.data.errors),
            type: "error",
          },
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
      } else {
        updateFields(res.data.fields, productFields, productDispatch);
        productDispatch({
          type: "setNewTreatmentFields",
          payload: res.data.treatments,
        });
        productDispatch({ type: "updatePrice", payload: res.data });
        productDispatch({
          type: "setMessage",
          payload: { message: null, type: null },
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
      }
    });
  }

  useEffect(() => {
    api
      .post(`catalog/items`, {
        category: "Charms",
        filters: {},
      })
      .then((res) => {
        const shapes = res.data.groups.find(function (filter) {
          return filter.name === "CharmShape";
        });
        const sortedShapes = shapes.values
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        setCharmShapeOptions(sortedShapes);
      });
    productDispatch({ type: "setPageLoaded", payload: true });
    //productDispatch({ type: "setPriceLoaded", payload: true });
    //productDispatch({ type: "setProductGroup", payload: props.ProductGroup });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.ProductStyleCode]);

  useEffect(() => {
    const strapiId = 44;
    const query = qs.stringify({
      populate: {
        left: {
          populate: "*",
        },
        middle: {
          populate: "*",
        },
        right: {
          populate: "*",
        },
        chart: {
          populate: "*",
        },
      },
    });
    api
      .fetchStrapi(`/product-details-charts/${strapiId}?${query}`)
      .then((response) => {
        productDispatch({
          type: "setStrapi",
          payload: {
            left: response.data.data.attributes.left,
            middle: response.data.data.attributes.middle,
            right: response.data.data.attributes.right,
            chart: response.data.data.attributes.chart,
            ChartSizeSmall: response.data.data.attributes.ChartSizeSmall,
            TopText: response.data.data.attributes.TopText,
            BottomText: response.data.data.attributes.BottomText,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isMill, props.match.params.ProductStyleCode]);

  //advertisements from Strapi
  useEffect(() => {
    const query = qs.stringify({
      populate: {
        One: {
          populate: "*",
        },
        Two: {
          populate: "*",
        },
        Three: {
          populate: "*",
        },
        Four: {
          populate: "*",
        },
        Five: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/detail-page-ad?${query}`).then((response) => {
      const ads = response.data.data.attributes;
      const randomAd = filterAds(ads);

      productDispatch({
        type: "setPageAd",
        payload: randomAd,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.ProductStyleCode]);

  useEffect(() => {
    api
      .post(`catalog/items`, {
        category: "Charms",
        filters: filter,
      })
      .then((res) => {
        const array = res.data.items.map((style) => ({
          label: style.description,
          value: style.style,
        }));
        setCharmStyles(array);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (charmStyle !== "None" && charmStyle !== null) {
      productDispatch({ type: "setPriceLoaded", payload: false });
      api.fetch(`quickorder/form?style=${charmStyle}`).then((response) => {
        if (response.isAxiosError) {
          const errorMessage = [];
          response.data.errors.forEach(function (error) {
            errorMessage.push(error);
          });
          toast.error(
            `There was an error processing your request. ${errorMessage.toString()}`
          );
        } else {
          createProductDefaults(response.data);
          functions.storeRecentlyViewed(response.data);
          productDispatch({
            type: "setCharmDimension",
            payload: response.data.dimension,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charmStyle]);

  useEffect(() => {
    if (authToken && pageLoaded && styleLoaded && productInfo.quantity > 0) {
      productDispatch({ type: "setPriceLoaded", payload: false });
      const newQueryString = makeQueryString();
      getUpdatedPrice(newQueryString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  return (
    <ProductsContext.Provider value={{ productState, productDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={6}
            sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          >
            <Grid item xs={12} md={5}>
              <Stack>
                <img
                  src={CharmsImage}
                  alt="Selected Product"
                  className="selected-product"
                />
                {charmShape && charmStyle && (
                  <DetailTable data={productDetails} />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ marginBottom: ".5rem" }}>
                <Typography variant="h4">Charms</Typography>
                <Typography>
                  Select a Charm Shape to start placing your order!
                </Typography>
                <Stack direction="row" spacing={1}>
                  {charmShape &&
                    charmStyle &&
                    icons &&
                    icons.map((icons, key) => (
                      <Typography key={icons} className="icons">
                        {functions.getIcons(icons)}
                      </Typography>
                    ))}
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <Stack>
                  <Typography
                    sx={{ fontWeight: "700", paddingBottom: ".3rem" }}
                  >
                    Charm Shape
                  </Typography>
                  <Select
                    value={charmShape === null ? "None" : charmShape}
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setCharmShape(e.target.value);
                      setCharmStyle(null);
                      setFilter([
                        {
                          name: "CharmShape",
                          values: [e.target.value],
                        },
                      ]);
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          border: "1px solid #939598",
                          borderRadius: "0",
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        },
                      },
                    }}
                  >
                    <MenuItem value="None">
                      <em>Select...</em>
                    </MenuItem>
                    {charmShapeOptions &&
                      charmShapeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                </Stack>
                {charmShape && (
                  <Stack>
                    <Typography
                      sx={{ fontWeight: "700", paddingBottom: ".3rem" }}
                    >
                      Charm Style
                    </Typography>
                    <Select
                      value={charmStyle === null ? "None" : charmStyle}
                      size="small"
                      sx={{ width: "100%" }}
                      onChange={(e) => {
                        setCharmStyle(e.target.value);
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            border: "1px solid #939598",
                            borderRadius: "0",
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          },
                        },
                      }}
                    >
                      <MenuItem value="None">
                        <em>Select...</em>
                      </MenuItem>
                      {charmStyles &&
                        charmStyles.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </Stack>
                )}
                {charmShape && charmStyle && (
                  <Divider flexItem sx={{ paddingTop: "1rem" }} />
                )}
                {charmShape &&
                  charmStyle &&
                  productFields &&
                  productFields.map((field) => {
                    if (field.name === "material") {
                      return (
                        <Material
                          field={field}
                          key={field.name}
                          isMill={props.isMill}
                        />
                      );
                    } else if (field.name === "thickness") {
                      return (
                        <Thickness
                          field={field}
                          key={field.name}
                          isMill={props.isMill}
                        />
                      );
                    } else if (field.name === "quantity") {
                      return (
                        <Quantity
                          field={field}
                          key={field.name}
                          isMill={props.isMill}
                        />
                      );
                    } else {
                      return "";
                    }
                  })}
                {charmShape && charmStyle && (
                  <Divider flexItem sx={{ paddingTop: "1rem" }} />
                )}
                {charmShape &&
                  charmStyle &&
                  productTreatments &&
                  productTreatments.map((treat) => {
                    return <TreatmentField field={treat} key={treat.label} />;
                  })}
                {charmShape && charmStyle && <SpecialInstructions />}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              {charmShape && charmStyle && <Price />}
              {advertisement && (
                <DomLink
                  to={{
                    pathname: `${advertisement.alternativeText}`,
                    state: {
                      recentlyViewed: JSON.parse(
                        storage.getSessionStorageItem(
                          "hsRecentlyViewedProducts"
                        )
                      ),
                    },
                  }}
                >
                  <img
                    style={{ marginTop: "4rem" }}
                    src={`${frontImageUrl}${advertisement.url}`}
                    width="100%"
                    alt="advertisement"
                  />
                </DomLink>
              )}
            </Grid>
          </Grid>
          {charmShape && charmStyle && (
            <>
              <CompatibleProduct compatibleProducts={compatibleProducts} />
              <RelatedItems relatedProducts={relatedProducts} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TechnicalArticles productGroup={productGroup} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomerTestimonials />
                </Grid>
              </Grid>
              <RecentlyView />
            </>
          )}
          {metalColumns && <MetalColumns metalColumns={metalColumns} />}
          {strapiChart && (
            <StrapiChart
              strapiChart={strapiChart}
              TopText={TopText}
              BottomText={BottomText}
              ChartSizeSmall={ChartSizeSmall}
            />
          )}
        </Container>
      )}
    </ProductsContext.Provider>
  );
}
